/**
 */
var modules = modules || {}
var EDIT_TYPE_ALL = 'ALL',
  EDIT_TYPE_FOLLOWING = 'FOLLOWING',
  EDIT_TYPE_OVERRIDE = 'OVERRIDE'
modules.accessslideout = (function () {
  const switchSlideoutCallback = e => {
    e.stopPropagation()
    e.preventDefault()

    try {
      const value = window.localStorage.getItem('new-ar-slideout')
      const newValue = value === 'false'

      window.localStorage.setItem('new-ar-slideout', newValue)
      window.location.reload()
    } catch (e) {
      console.error(`Cannot access local storage ${e}`)
    }
  }

  const checkUseNewArSlideout = () => {
    let useNewArSlideout = true

    try {
      const userSetting = window.localStorage.getItem('new-ar-slideout')
      if (userSetting !== null) {
        useNewArSlideout = userSetting === 'true'
      }
    } catch (e) {
      console.error(`Cannot access local storage ${e}`)
    }

    return useNewArSlideout
  }

  const switchSlideoutComponent = (
    <div
      style={{
        fontFamily: 'Roboto',
        fontSize: 14,
        textAlign: 'center',
        padding: '16px',
        color: '#777777',
      }}
    >
      You're viewing the Classic Editor.
      <a
        style={{
          cursor: 'pointer',
          color: '#347baf',
          background: '#FFFFFF',
          border: '1px solid #347baf',
          borderRadius: '4px',
          padding: '7px 16px',
          marginLeft: '8px',
        }}
        onClick={switchSlideoutCallback}
        data-test="access-rules-switch-slideout"
      >
        Try New Editor &#10024;
      </a>
    </div>
  )

  const perkBanner = ({ data, exclusivePerkId, hasMultiplePerks }) =>
    hasMultiplePerks || data ? (
      <div
        style={{
          fontFamily: 'Roboto',
          fontSize: 14,
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          color: '#0A0A0A',
          padding: '16px 24px',
          background: '#FFF4EC',
        }}
      >
        <div style={{ flex: 1, lineHeight: '18px' }}>
          <p style={{ fontWeight: 700, marginBottom: 4 }}>Linked to Perk</p>
          <p>
            {hasMultiplePerks
              ? 'This Access Rule is linked to multiple Perks.'
              : `This Access Rule is linked to the Perk: ${data?.external_name}.`}
          </p>
        </div>
        <a
          style={{
            cursor: 'pointer',
            color: '#347baf',
            background: '#FFFFFF',
            border: '1px solid #347baf',
            borderRadius: '4px',
            padding: '7px 16px',
            marginLeft: '8px',
          }}
          href={`${window.location.origin}/manager2/${window.globalInit.venueUrlKey}/marketing/perks2.0/${
            data?.id ? `${data.id}/${exclusivePerkId ? 'edit-exclusive' : 'edit-early-access'}/` : ''
          }`}
          data-test="access-rules-go-to-perk"
        >
          {hasMultiplePerks ? 'Manage Perks' : 'Go to Perk'}
        </a>
      </div>
    ) : null

  var helpers = {
    weekDaysOptions: {
      Sunday: 'S',
      Monday: 'M',
      Tuesday: 'T',
      Wednesday: 'W',
      Thursday: 'TH',
      Friday: 'F',
      Saturday: 'SA',
    },

    topLevelAccessGroups: ['DIRECT_CHANNELS', 'DIRECT_WAITLIST', 'THIRD_PARTY_CHANNELS'],

    shiftCategoryOptions: _.object([
      ['BREAKFAST', 'Breakfast'],
      ['BRUNCH', 'Brunch'],
      ['LUNCH', 'Lunch'],
      ['DAY', 'Day'],
      ['DINNER', 'Dinner'],
      ['LEGACY', 'Night'],
    ]),

    shiftCategoryDropdownOptions: function (shiftCategoryList, dropDownName) {
      return shiftCategoryList
        .filter(shiftCategory => shiftCategory)
        .map(shiftCategory => ({
          id: shiftCategory,
          value: helpers.shiftCategoryOptions[shiftCategory],
          name: dropDownName,
        }))
    },

    accessDaySection: React.createClass({
      onClickHandler: function (e) {
        var currentTarget = $(e.currentTarget),
          isChecked = $(currentTarget).is(':checked'),
          selectedDay = $(currentTarget).data('additional'),
          currentWeekDate = new Date(this.props.startDay),
          dayName = currentWeekDate.toLocaleString('default', { weekday: 'long' }),
          dayIndex = _.indexOf(_.keys(helpers.weekDaysOptions), dayName)
        currentWeekDate.addDays(-dayIndex)
        var dateLookup = _.object(
            _.times(7, function (i) {
              var tuple = [currentWeekDate.toLocaleString('default', { weekday: 'long' }), formatDate(currentWeekDate)]
              currentWeekDate.addDays(1)
              return tuple
            })
          ),
          startTime = $(currentTarget).closest('form').find('[name=start_time]').val(),
          endTime = $(currentTarget).closest('form').find('[name=end_time]').val(),
          date = dateLookup[selectedDay]
        // Possible that we don't have time selectors (when specific or all times are selected)
        if (startTime && endTime) {
          var startTimeParts = modules.weekViewManager.getHourFraction(startTime),
            endTimeParts = modules.weekViewManager.getHourFraction(endTime),
            startDateTime = new Date(dateLookup[selectedDay] + ' ' + startTime),
            endDateTime = new Date(dateLookup[selectedDay] + ' ' + endTime)

          startDateTime.addDays(startTimeParts.hour >= 0 && startTimeParts.hour < 6 ? 1 : 0)
          endDateTime.addDays(endTimeParts.hour >= 0 && endTimeParts.hour < 6 ? 1 : 0)

          var hours = startDateTime.getHours(),
            fraction = startDateTime.getMinutes() / 15,
            total_duration = (endDateTime - startDateTime) / 3600000

          if (helpers.mode === 'add') {
            this.props.addOverlayCallback(date, hours, fraction, total_duration, isChecked)
          }
        }
      },

      render: function () {
        var postInputCss = {
            clear: 'both',
            margin: 0,
            border: 0,
            color: '#000 !important',
            width: 14,
            backgroundColor: 'transparent !important',
          },
          divCss = {
            width: 55,
          }

        var options = _.map(
          helpers.weekDaysOptions,
          function (value, key) {
            return (
              <modules.formElements.create
                type="checkbox"
                label={value}
                key={key}
                data-name={key}
                name="days"
                value={value}
                dataAdditional={key}
                checked={_.contains(this.props.selectedDays, value)}
                validator-type="not_empty"
                postLabelStyle={postInputCss}
                inputCss={_.extend({ marginLeft: 4 }, { border: 0 })}
                id={'id_rule_' + key}
                style={divCss}
                preInputStyle={{ position: 'relative', left: 0, border: '1px solid #ccc', padding: 2 }}
                validator="not_empty"
                onClickHandler={this.onClickHandler}
                validator_message="Please select a day"
              />
            )
          },
          this
        )

        return <div className="radioset">{options}</div>
      },
    }),

    accessRuleName: React.createClass({
      render: function () {
        return (
          <div className="inline schedule-name" style={{}}>
            <modules.formElements.create
              name="name"
              validator="not_empty"
              labelText="Rule name"
              preLabelStyle={{ color: '#777', margin: '10px 0 10px' }}
              prelabel="Rule Name"
              required="True"
              value={this.props.value}
              validator_message="Please enter the access rule name"
            />
          </div>
        )
      },
    }),

    twoDropDowns: React.createClass({
      render: function () {
        var selectCss = { border: 0 }
        return (
          <div className="inline" style={{ width: '100%', paddingBottom: 0, paddingLeft: 20 }}>
            {this.props.label ? (
              <p className="group-label">
                {this.props.label}
                <span className="required">*</span>
              </p>
            ) : undefined}
            <modules.formElements.create
              type="select"
              inputCss={selectCss}
              disabled={this.props.disabled}
              onChangeHandler={this.props.select1.onChangeHandler}
              value={this.props.select1.selected}
              name={this.props.select1.name}
              classes={this.props.select1.classes}
              preLabelStyle={{ fontSize: 11, fontWeight: 'normal', fontStyle: 'italic' }}
              options={this.props.select1.options}
              media_url={this.props.media_url}
              labelText={this.props.select1.id}
            />
            <modules.formElements.create
              type="select"
              inputCss={selectCss}
              disabled={this.props.disabled}
              onChangeHandler={this.props.select2.onChangeHandler}
              name={this.props.select2.name}
              value={this.props.select2.selected}
              classes={this.props.select2.classes}
              preLabelStyle={{ fontSize: 11, fontWeight: 'normal', fontStyle: 'italic' }}
              options={this.props.select2.options}
              media_url={this.props.media_url}
              labelText={this.props.select2.id}
            />
          </div>
        )
      },
    }),

    getAvailableTimes: function (startTime, endTime, inclusive) {
      var interval_minutes = 15,
        interval_multiplier = interval_minutes / 15

      var allTimes = _.filter(
        _.flatten(
          _.times(24, function (hour) {
            hour = (hour + Pmp.Manager.Global.start_of_day_hour) % 24
            return _.times(4, function (fraction) {
              return modules.weekViewManager.getMinutesDisplay(hour * 60 + fraction * 15)
            })
          })
        ),
        function (v, k) {
          return k % interval_multiplier === 0
        }
      )
      var startIndex = startTime ? _.indexOf(allTimes, startTime) : 0,
        endIndex = endTime ? _.indexOf(allTimes, endTime) : _.size(allTimes),
        applicableTimes = allTimes.slice(startIndex, endIndex + !!inclusive)

      return _.object(applicableTimes, applicableTimes)
    },

    getDurationOptions: function () {
      var merged = {}
      _.times(
        16 * 4,
        function (quarter_hours) {
          quarter_hours += 1
          merged[quarter_hours * 15] = [Math.floor(quarter_hours / 4), 'HR', ('0' + (quarter_hours % 4) * 15).slice(-2), 'MIN'].join(' ')
        },
        this
      )
      return merged
    },

    minsToDisplayTime: function (total_mins) {
      if (Number(total_mins) == 0) {
        return '0 mins'
      }
      var mins = total_mins % 60,
        hrs = Math.floor(total_mins / 60),
        hrs_post_fix = hrs == 1 ? 'hr' : 'hrs',
        mins_post_fix = mins == 1 ? 'min' : 'mins',
        hrs_part = hrs ? hrs + ' ' + hrs_post_fix : '',
        mins_part = mins ? mins + ' ' + mins_post_fix : ''

      return hrs_part + ' ' + mins_part
    },

    accessTimeSelection: React.createClass({
      getInitialState: function () {
        var defaultAccessType = this.props.selectionType === 'BOOKING_CUTOFF' ? 'HOURS' : 'DAYS'
        var accessType = this.props.type ? this.props.type : defaultAccessType
        var defaultHour
        if (this.props.selectionType === 'BOOKING_CUTOFF' || accessType === 'HOURS') {
          defaultHour = '0'
        } else {
          defaultHour = modules.weekViewManager.getMinutesDisplay(60 * 10)
        }
        const accessHour = this.props.hour !== undefined ? this.props.hour : defaultHour
        return {
          type: accessType,
          num: this.props.num,
          hour: accessHour,
          is_disabled: this.props.disabled,
        }
      },

      onChangeHandler: function (e) {
        var value = $(e.currentTarget).val()
        this.setState({ type: value })
      },

      onNumChangeHandler: function (e) {
        this.setState({
          num: $(e.currentTarget).val(),
        })
      },

      render: function () {
        var firstOptions = _.extend(this.props.allowMinutes ? { MINUTES: 'minutes in advance of' } : {}, {
          HOURS: 'hours in advance of',
          DAYS: 'days in advance of',
          WEEKS: 'weeks in advance of',
          MONTHS: 'months in advance of',
        })
        var secondOptions = _.extend({}, { 0: 'reservation time' }, this.state.type === 'HOURS' ? [] : helpers.getAvailableTimes())
        return (
          <div>
            {this.props.include_title ? (
              <div className="inline" style={{ display: this.state.is_disabled ? 'none' : 'block' }}>
                <p className="group-label">How far in advance can they book?</p>
              </div>
            ) : undefined}
            <div className="inline" style={{ margin: '10px 0px', display: this.state.is_disabled ? 'none' : 'block' }}>
              <modules.formElements.create
                type="number"
                min="1"
                onChangeHandler={this.onNumChangeHandler}
                value={this.state.num}
                disabled={this.state.is_disabled || this.props.disableEdit}
                validator={'access_time_in_bounds'}
                validator_inject={`[name=${this.props.name_2}]`}
                name={this.props.name_1}
                validator_message="Number is too large, please use a smaller number"
                inputCss={{ width: 40, padding: '6px 8px' }}
                style={{ clear: 'both', width: 60, backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto' }}
              />
              <modules.formElements.create
                type="select"
                disabled={this.state.is_disabled || this.props.disableEdit}
                name={this.props.name_2}
                onChangeHandler={this.onChangeHandler}
                media_url={this.props.media_url}
                preInputStyle={{ width: '10.5em' }}
                options={firstOptions}
                value={this.state.type}
                inputCss={{ padding: '6px 8px', border: 0 }}
                style={{ backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto', clear: 'right' }}
              />
              <modules.formElements.create
                type="select"
                name={this.props.name_3}
                disabled={this.props.disableEdit}
                preInputStyle={{ width: '130px' }}
                value={this.state.hour}
                media_url={this.props.media_url}
                options={secondOptions}
                inputCss={{ padding: '6px 8px', border: 0 }}
                style={{
                  backgroundColor: '#FFF',
                  float: 'left',
                  fontFamily: 'Roboto',
                  clear: 'right',
                  marginRight: '0',
                }}
              />
            </div>
          </div>
        )
      },
    }),

    inlinedDropDown: React.createClass({
      getInitialState: function () {
        var that = this,
          mapFlattenOptions = function (x) {
            if (x['options']) {
              return [x].concat(_.flatten(_.map(x['options'], mapFlattenOptions)))
            } else {
              return x
            }
          },
          mapFlattenGroups = function (x) {
            if (x['options']) {
              return [x].concat(_.flatten(_.map(x['options'], mapFlattenGroups)))
            } else {
              return []
            }
          },
          flattenedOptions = _.flatten(_.map(this.props.options, mapFlattenOptions)),
          flattenedGroups = _.flatten(_.map(this.props.options, mapFlattenGroups)),
          selectedIds = _.pluck(this.props.initialList, 'id'),
          manuallySelectedGroupIds = _.pluck(
            _.filter(flattenedGroups, function (x) {
              return _.contains(selectedIds, x.id)
            }),
            'id'
          ),
          collapsedGroups = []
        if (this.props.startCollapsed) {
          collapsedGroups = _.difference(
            _.pluck(
              _.filter(flattenedOptions, function (group) {
                var numNestedChildren = that.getNumberNestedChildren(group, selectedIds),
                  isSelected = that.isGroupSelected(group, numNestedChildren, manuallySelectedGroupIds)
                return numNestedChildren.total_selected === 0 || isSelected
              }),
              'id'
            ),
            helpers.topLevelAccessGroups
          )
        }
        return {
          selectedList: this.props.initialList || [],
          flattenedOptions: flattenedOptions,
          flattenedGroups: flattenedGroups,
          collapsedGroups: collapsedGroups,
          manuallySelectedGroupIds: manuallySelectedGroupIds,
          isFirstExpansion: true,
        }
      },

      onMouseOver: function (e) {
        $(e.currentTarget).css('background-color', '#DDDDDD')
      },

      onMouseOut: function (e) {
        $(e.currentTarget).css('background-color', '#FFFFFF')
      },

      componentDidUpdate: function (prevProps, prevState) {
        if (this.props.propagationHandler) {
          this.props.propagationHandler(this.state.selectedList)
        }
        if (this.props.postActionFunc) {
          this.props.postActionFunc()
        }
      },

      flipSingle: function (e) {
        if ($(e.currentTarget).find('input[type=checkbox]').is(':checked')) {
          this.onRemove(e)
        } else {
          this.onAdd(e)
        }
        e.nativeEvent.preventDefault()
      },

      onAdd: function (e) {
        var id = $(e.currentTarget).attr('data-id'),
          value = $(e.currentTarget).data('value'),
          item = _.find(this.state.selectedList, { id: id })
        if (!item) {
          var selectedList = this.state.selectedList,
            selectedItem = _.find(this.state.flattenedOptions, { id: id })
          if (this.props.limit && _.size(selectedList) === this.props.limit) {
            selectedList.pop()
          }
          if (selectedItem) {
            selectedList.push(selectedItem)
            this.setState({ selectedList: selectedList })
          }
        }
      },

      addOrRemoveAll: function (isChecked, group, e) {
        // First propagate to nested groups
        var groupOption = _.findWhere(this.state.flattenedGroups, { id: group }),
          allNestedChildrenIds = function (item) {
            return _.flatten(
              _.map(item.options, function (item) {
                if (item.type === 'grouped') {
                  return allNestedChildrenIds(item)
                } else {
                  return [item.id]
                }
              })
            )
          },
          childrenIds = allNestedChildrenIds(groupOption),
          existingListSansCurrentGroup = _.filter(this.state.selectedList, function (item) {
            return !_.contains(childrenIds, item.id)
          }),
          newItems = _.filter(this.state.flattenedOptions, function (option) {
            return _.contains(childrenIds, option['id'])
          }),
          existingGroupsSansCurrentGroup = _.filter(this.state.manuallySelectedGroupIds, function (groupId) {
            return groupId !== group
          }),
          newGroups = [group]
        if (isChecked) {
          this.setState({
            selectedList: existingListSansCurrentGroup,
            manuallySelectedGroupIds: existingGroupsSansCurrentGroup,
          })
        } else {
          this.setState({
            selectedList: existingListSansCurrentGroup.concat(newItems),
            manuallySelectedGroupIds: existingGroupsSansCurrentGroup.concat(newGroups),
          })
        }

        e.preventDefault()
        e.stopPropagation()
      },

      onRemoveAll: function (e) {
        var group = $(e.currentTarget).attr('data-group')
        this.addOrRemoveAll(true, group, e)
      },

      onRemove: function (e) {
        var id = $(e.currentTarget).attr('data-id'),
          selectedItem = _.find(this.state.selectedList, { id: id })
        if (selectedItem) {
          var selectedList = this.state.selectedList,
            index = _.indexOf(selectedList, selectedItem)
          selectedList.splice(index, 1)
          this.setState({ selectedList: selectedList })
        }
        e.stopPropagation()
      },

      onClick: function () {
        if (!this.props.disabled) {
          $(this.getDOMNode()).find('.utildrop').css('display', 'block')
        }
      },

      generateOption: function (item, key, isChecked, type) {
        return (
          <div
            key={key}
            style={{
              cursor: 'pointer',
              padding: 5,
              fontFamily: 'Roboto',
              fontSize: 14,
              color: isChecked ? '#555555' : '#989898',
            }}
            onClick={type === 'All' ? _.partial(this.addOrRemoveAll, isChecked, item.group) : this.flipSingle}
            onMouseOver={this.onMouseOver}
            onMouseOut={this.onMouseOut}
            data-value={item['value']}
            data-type={item['type']}
            data-group={item['group']}
            data-id={item['id']}
          >
            <modules.formElements.create
              key={'checkbox_' + key}
              type="checkbox"
              data-value={item['value']}
              data-type={item['type']}
              data-id={item['id']}
              checked={isChecked}
              inputCss={{ width: 0, padding: 2, margin: 3, height: 0 }}
              preInputStyle={{ height: 9, width: 9, backgroundSize: 8 }}
              style={{ clear: 'both' }}
              labelStyle={{ margin: 0 }}
            />
            {item['value']}
          </div>
        )
      },

      onExpansionClickHandler: function (e) {
        var selectedGroup = $(e.currentTarget).attr('data-group'),
          collapsedGroups = this.state.collapsedGroups,
          index = _.indexOf(collapsedGroups, selectedGroup)
        if (index > -1) {
          collapsedGroups.splice(index, 1)
        } else {
          collapsedGroups.push(selectedGroup)
        }
        this.setState({ collapsedGroups: collapsedGroups })
      },

      isGroupSelected: function (group, numNestedChildren, manuallySelectedGroupIds) {
        var groupRequiresManualAllSelection = _.contains(this.props.groupIdsRequiringManualAllSelection, group.id)
        return groupRequiresManualAllSelection
          ? _.contains(manuallySelectedGroupIds, group.id)
          : numNestedChildren.total_selected === numNestedChildren.total_available
      },

      generateGroupedOptions: function (selectedIds, selectedGroupIds, groupedOptionObj, nestedCount) {
        var numNestedChildren = this.getNumberNestedChildren(groupedOptionObj, selectedIds)
        var isSelected = this.isGroupSelected(groupedOptionObj, numNestedChildren, this.state.manuallySelectedGroupIds)
        var listOptions = groupedOptionObj.options.map(function (item, key) {
          if (item.type === 'grouped') {
            return this.generateGroupedOptions(selectedIds, selectedGroupIds, item, nestedCount + 1)
          } else {
            return this.generateOption(item, key, _.contains(selectedIds, item['id']))
          }
        }, this)
        var isGroupExpanded = !_.contains(this.state.collapsedGroups, groupedOptionObj.id)
        var parentHeader = groupedOptionObj.label + ' (' + numNestedChildren.total_selected + '/' + numNestedChildren.total_available + ')'
        var allOptionLabel = 'All ' + groupedOptionObj.label
        var isTopLevelAccessGroup = _.contains(helpers.topLevelAccessGroups, groupedOptionObj.id)

        return (
          <div
            key={groupedOptionObj.id}
            style={{
              borderTop: nestedCount === 0 ? '1px solid #F1F1F1' : 'none',
              marginLeft: 10 * nestedCount,
            }}
          >
            <div>
              <div
                onClick={this.onExpansionClickHandler}
                data-group={groupedOptionObj.id}
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Roboto',
                  textTransform: 'uppercase',
                  padding: '5px 8px',
                  fontSize: isTopLevelAccessGroup ? 12 : 10,
                }}
              >
                <span style={{ color: '#50ABA6' }}>{isGroupExpanded ? String.fromCharCode(8212) + ' ' : '+ '}</span>
                <span style={{ color: '#50ABA6' }}>{parentHeader}</span>
              </div>
              {this.generateOption(
                {
                  type: 'All',
                  id: 'All',
                  group: groupedOptionObj.id,
                  value: allOptionLabel,
                },
                'All',
                isSelected,
                'All'
              )}
              {isSelected && <input type="hidden" name={this.props.name + '_selected_group'} value={groupedOptionObj.id} />}
            </div>
            {isGroupExpanded ? listOptions : null}
          </div>
        )
      },

      getNumberNestedChildren: function (item, selectedIds, accum) {
        return _.reduce(
          item.options,
          _.partial(this.reduceNumberNestedChildren, selectedIds),
          accum || {
            total_selected: 0,
            total_available: 0,
          }
        )
      },

      reduceNumberNestedChildren: function (selectedIds, accum, item) {
        if (item.type === 'grouped') {
          this.getNumberNestedChildren(item, selectedIds, accum)
        } else {
          accum.total_available += 1
          accum.total_selected += _.contains(selectedIds, item.id) ? 1 : 0
        }
        return accum
      },

      render: function () {
        var that = this,
          selectedIds = _.pluck(this.state.selectedList, 'id'),
          inlineDisplayOptions = _.filter(
            _.map(that.props.options, function (item, key) {
              if (item.type === 'grouped') {
                var numNestedChildren = that.getNumberNestedChildren(item, selectedIds)
                if (numNestedChildren.total_selected > 0) {
                  return _.extend({}, item, numNestedChildren)
                } else {
                  return undefined
                }
              } else {
                return _.contains(selectedIds, item['id']) ? item : undefined
              }
            })
          ),
          renderOptions = that.props.options.map(function (item, key) {
            if (item.type === 'grouped') {
              var selectedGroupIds = _.pluck(_.where(that.state.selectedList, { group: item['id'] }), 'id')
              return that.generateGroupedOptions(selectedIds, selectedGroupIds, item, 0)
            } else {
              return that.generateOption(item, key, _.contains(selectedIds, item['id']))
            }
          })

        return (
          <div data-label={this.props.label} key={this.props.label} className="inline">
            <div className="inline">
              <div
                onClick={this.onClick}
                style={{
                  float: 'left',
                  clear: 'both',
                  width: 350,
                  backgroundColor: this.props.disabled ? '#BBB' : '#FFF',
                  minHeight: 20,
                  padding: 5,
                  marginTop: 10,
                  border: '1px solid #CACACA',
                }}
              >
                <helpers.selectedInputInline
                  disabled={this.props.disabled}
                  name={this.props.name}
                  inlineDisplayOptions={inlineDisplayOptions}
                  selectedList={this.state.selectedList}
                  onRemove={this.onRemove}
                  onRemoveAll={this.onRemoveAll}
                  shouldHide={this.props.shouldHide}
                />
              </div>
              <div
                className="utildrop"
                style={{
                  position: 'initial',
                  clear: 'both',
                  display: 'none',
                  width: 360,
                  border: '1px solid #CACACA',
                  color: '#989898',
                  float: 'left',
                  maxHeight: 250,
                  overflowY: 'scroll',
                }}
              >
                {renderOptions}
              </div>
            </div>
          </div>
        )
      },
    }),

    specificTime: React.createClass({
      render: function () {
        var options = _.map(helpers.getAvailableTimes(), function (opt) {
            return {
              id: opt,
              value: opt,
              name: 'specific_times',
            }
          }),
          selectedOptions = _.isEmpty(this.props.specific_times_display)
            ? []
            : _.map(this.props.specific_times_display, function (t) {
                return _.findWhere(options, { id: t })
              })
        return (
          <div className="inline" style={{ width: '100%', paddingBottom: 0, paddingLeft: 20 }}>
            <helpers.inlinedDropDown
              key="specific_times"
              name="specific_times"
              label="specific-time"
              disabled={this.props.disabled}
              initialList={selectedOptions}
              options={options}
              propagationHandler={this.props.onUpdatedList}
            />
          </div>
        )
      },
    }),

    accessruleTimeIntervals: React.createClass({
      getInitialState: function () {
        var startTime = this.props.startTime,
          endTime = this.props.endTime
        return {
          startTime: startTime,
          endTime: endTime,
          restrictToShifts: this.props.restrictToShifts,
          selected_type: this.props.access_time_type ? this.props.access_time_type : 'ALL',
        }
      },

      onClick: function (e) {
        var selected_type = $(e.currentTarget).val()
        this.setState({ selected_type: selected_type })
        helpers.customPacingComponent.setState({ accessTimeType: selected_type })
      },

      onChangeTimeRangeHandler: function (e) {
        var startTime = $(e.currentTarget).filter('[name=start_time]'),
          endTime = $(e.currentTarget).filter('[name=end_time]'),
          allOptions = _.values(helpers.getAvailableTimes()),
          maxIndex = _.size(allOptions) - 1
        if (startTime.length) {
          var prevEndTime = this.state.endTime || allOptions[0],
            startTimeIndex = _.indexOf(allOptions, startTime.val()),
            endTimeIndex = _.indexOf(allOptions, prevEndTime),
            isEndTimeInvalid = endTimeIndex <= startTimeIndex,
            newEndTime = isEndTimeInvalid ? allOptions[maxIndex - startTimeIndex > 12 ? startTimeIndex + 12 : maxIndex] : prevEndTime
          this.setState({ startTime: startTime.val(), endTime: newEndTime })

          if (this.props.host_page === 'DAYVIEW') {
            modules.accessrulesDayViewManager.resetResizableOverlays(startTime.val(), newEndTime)
          } else {
            modules.accessrulesWeekViewManager.resetResizableOverlays(startTime.val(), newEndTime)
          }

          helpers.customPacingComponent.setState({
            accessTimeType: this.state.selected_type,
            startTime: startTime.val(),
            endTime: newEndTime,
          })
        }
        if (endTime.length) {
          var prevStartTime = this.state.startTime || allOptions[0],
            startTimeIndex = _.indexOf(allOptions, prevStartTime),
            endTimeIndex = _.indexOf(allOptions, endTime.val()),
            isStartTimeInvalid = endTimeIndex <= startTimeIndex,
            newStartTime = isStartTimeInvalid ? allOptions[endTimeIndex > 12 ? endTimeIndex - 12 : 0] : prevStartTime
          this.setState({ startTime: newStartTime, endTime: endTime.val() })
          if (this.props.host_page === 'DAYVIEW') {
            modules.accessrulesDayViewManager.resetResizableOverlays(newStartTime, endTime.val())
          } else {
            modules.accessrulesWeekViewManager.resetResizableOverlays(newStartTime, endTime.val())
          }
          helpers.customPacingComponent.setState({
            accessTimeType: this.state.selected_type,
            startTime: newStartTime,
            endTime: endTime.val(),
          })
        }
      },

      onUpdatedSpecificTimesHandler: function (specific_times_objs) {
        var specificTimes = _.map(specific_times_objs, function (t) {
          return t.value
        })
        helpers.customPacingComponent.setState({ accessTimeType: this.state.selected_type, specificTimes: specificTimes })
      },

      onRestrictToShiftsClick: function (e) {
        this.setState({
          restrictToShifts: $(e.currentTarget).is(':checked'),
        })
      },

      render: function () {
        var commonCss = {
          inputCss: { position: 'relative', left: 0, float: 'left', border: 0 },
          preLabelStyle: { paddingTop: '10px' },
          preInputStyle: { float: 'left', width: 'auto', height: 'auto', paddingTop: 10 },
          postLabelStyle: { margin: 0, width: 300, paddingTop: 10, paddingLeft: 20 },
        }
        var shiftCategoryKeys = Object.keys(helpers.shiftCategoryOptions)
        var selectedShifts = helpers.shiftCategoryDropdownOptions(this.props.shift_categories, 'shift_categories')
        return (
          <div style={{ paddingTop: 10, clear: 'both' }}>
            <p className="group-label" style={{ color: '#777' }}>
              <span>Time slots</span>
            </p>
            <modules.formElements.create
              type="radio"
              name="access_time_type"
              id="access_time_type_all"
              onClickHandler={this.onClick}
              data-start-time="0"
              data-end-time="0"
              defaultradio={this.state.selected_type === 'ALL'}
              value="ALL"
              {...commonCss}
              label={'All time slots during shift(s)'}
            />
            <div style={{ display: this.state.selected_type === 'ALL' ? 'block' : 'none' }}>
              <helpers.inlinedDropDown
                key="shift_categories"
                name="shift_categories"
                label="shift_categories"
                options={helpers.shiftCategoryDropdownOptions(shiftCategoryKeys, 'shift_categories')}
                flattenedOptions={helpers.shiftCategoryDropdownOptions(shiftCategoryKeys, 'shift_categories')}
                propagationHandler={this.props.onUpdatedList}
                initialList={selectedShifts}
                shouldHide={this.state.selected_type !== 'ALL'}
              />
            </div>
            <modules.formElements.create
              type="radio"
              name="access_time_type"
              id="access_time_type_time_range"
              onClickHandler={this.onClick}
              defaultradio={this.state.selected_type === 'TIME_RANGE'}
              value="TIME_RANGE"
              {...commonCss}
              label="Custom time range"
            />
            {this.state.selected_type === 'TIME_RANGE' ? (
              <helpers.twoDropDowns
                select1={{
                  name: 'start_time',
                  onChangeHandler: this.onChangeTimeRangeHandler,
                  selected: this.state.startTime,
                  options: helpers.getAvailableTimes(),
                  id: 'First seating',
                }}
                select2={{
                  name: 'end_time',
                  onChangeHandler: this.onChangeTimeRangeHandler,
                  selected: this.state.endTime,
                  options: helpers.getAvailableTimes(this.state.startTime),
                  id: 'Last seating',
                }}
                media_url={this.props.media_url}
                disabled={this.state.selected_type !== 'TIME_RANGE'}
              />
            ) : undefined}
            <modules.formElements.create
              type="radio"
              name="access_time_type"
              id="access_time_type_specific"
              onClickHandler={this.onClick}
              defaultradio={this.state.selected_type === 'SPECIFIC'}
              value="SPECIFIC"
              {...commonCss}
              label="Specific time slot(s)"
            />
            {this.state.selected_type === 'SPECIFIC' ? (
              <helpers.specificTime
                startTime={this.props.startTime}
                onUpdatedList={this.onUpdatedSpecificTimesHandler}
                specific_times_display={this.props.specific_times_display}
                endTime={this.props.endTime}
                disabled={this.state.selected_type !== 'SPECIFIC'}
              />
            ) : undefined}
            <div
              style={{ display: this.state.selected_type === 'SPECIFIC' || this.state.selected_type === 'TIME_RANGE' ? 'block' : 'none' }}
            >
              <modules.formElements.create
                type="checkbox"
                name="restrict_to_shifts"
                checked={this.state.restrictToShifts}
                value={1}
                onClickHandler={this.onRestrictToShiftsClick}
                label="Restrict to shift(s)"
                inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                labelStyle={{ margin: 0 }}
                style={{ clear: 'both', backgroundColor: '#FFF', marginTop: 10, float: 'left', fontFamily: 'Roboto' }}
              />
              <div style={{ display: this.state.restrictToShifts ? 'block' : 'none' }}>
                <helpers.inlinedDropDown
                  key="shift_categories"
                  name="shift_categories"
                  label="shift_categories"
                  options={helpers.shiftCategoryDropdownOptions(shiftCategoryKeys, 'shift_categories')}
                  flattenedOptions={helpers.shiftCategoryDropdownOptions(shiftCategoryKeys, 'shift_categories')}
                  propagationHandler={this.props.onUpdatedList}
                  initialList={selectedShifts}
                  shouldHide={!this.state.restrictToShifts}
                />
              </div>
            </div>
          </div>
        )
      },
    }),

    partiesSelection: React.createClass({
      getInitialState: function () {
        return {
          min: this.props.min,
          max: this.props.max,
          disabled: this.props.min === null && this.props.max === null,
        }
      },

      onCheckboxClick: function (e) {
        if (this.refs.party_size_min_dropdown) {
          this.refs.party_size_min_dropdown.setState({
            is_disabled: !$(e.currentTarget).is(':checked'),
          })
        }
        if (this.refs.party_size_max_dropdown) {
          this.refs.party_size_max_dropdown.setState({
            is_disabled: !$(e.currentTarget).is(':checked'),
          })
        }
        this.setState({
          disabled: !$(e.currentTarget).is(':checked'),
        })
      },

      onChangeHandler: function (e) {
        var selectedVal = Number($(e.currentTarget).val())
        if ($(e.currentTarget).filter('[name=party_size_min]').length) {
          this.setState({
            min: Number($(e.currentTarget).val()),
          })
          this.props.onUpdateHandler(selectedVal, this.state.max)
        }
        if ($(e.currentTarget).filter('[name=party_size_max]').length) {
          this.setState({
            max: Number($(e.currentTarget).val()),
          })
          this.props.onUpdateHandler(this.state.min, selectedVal)
        }
      },

      render: function () {
        return (
          <div>
            <div className="inline">
              <modules.formElements.create
                type="checkbox"
                name="access_type_party_size"
                checked={!this.state.disabled}
                onClickHandler={this.onCheckboxClick}
                label="Restrict by party size"
                inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                labelStyle={{ margin: 0 }}
                style={{ clear: 'both', backgroundColor: '#FFF', marginTop: 10, float: 'left', fontFamily: 'Roboto' }}
              />
            </div>
            {!this.state.disabled ? (
              <div className="inline" style={{ width: '100%', paddingLeft: 20 }}>
                <modules.formElements.create
                  value={this.state.min}
                  style={{ fontFamily: 'Roboto', fontStyle: 'italic' }}
                  labelText="Party size min"
                  onChangeHandler={this.onChangeHandler}
                  validator="int_positive_or_empty"
                  min="1"
                  name="party_size_min"
                  preLabelStyle={{ fontSize: 11, fontWeight: 'normal' }}
                  validator_message="Accepts > 0 only"
                  ref="party_size_min_dropdown"
                  disabled={this.state.disabled}
                  type="number"
                />

                <modules.formElements.create
                  style={{ fontFamily: 'Roboto', fontStyle: 'italic' }}
                  onChangeHandler={this.onChangeHandler}
                  min={this.state.min}
                  value={this.state.max}
                  labelText="Party size max"
                  preLabelStyle={{ fontSize: 11, fontWeight: 'normal' }}
                  validator="int_positive_or_empty"
                  ref="party_size_max_dropdown"
                  disabled={this.state.disabled}
                  name="party_size_max"
                  validator_message="Accepts > 0 only"
                  type="number"
                />
              </div>
            ) : undefined}
          </div>
        )
      },
    }),

    accessruleDaySelection: React.createClass({
      render: function () {
        return (
          <div className="inline group-size" style={{ display: this.props.hidden ? 'none' : 'block' }}>
            <input id="schedule-day" type="hidden" value="2" className="auto -schedule_day" name="schedule_day" />

            <p className="group-label" style={{ color: '#777', top: 0 }}>
              Days of week to apply this rule
              <span className="required">*</span>
            </p>
            <helpers.accessDaySection
              selectedDays={this.props.selectedDays}
              addOverlayCallback={this.props.addOverlayCallback}
              startDay={this.props.startDay}
              disabled={this.props.disabled}
            />
          </div>
        )
      },
    }),

    selectedInputInline: React.createClass({
      onMouseOver: function (e) {
        $(e.currentTarget).css('background-color', '#E1E1E1')
        $(e.currentTarget).find('.close').css({ visibility: 'visible' })
      },

      onMouseOut: function (e) {
        $(e.currentTarget).css('background-color', '#F1F1F1')
        $(e.currentTarget).find('.close').css({ visibility: 'hidden' })
      },

      render: function () {
        var potentialOptions = this.props.selectedList.length > 10 ? this.props.inlineDisplayOptions : this.props.selectedList,
          inlinedDisplayData = _.map(
            potentialOptions,
            function (selectedObj, key) {
              var label =
                selectedObj['type'] === 'grouped'
                  ? selectedObj['label'] + ' (' + selectedObj['total_selected'] + '/' + selectedObj['total_available'] + ')'
                  : selectedObj['value']
              return (
                <div
                  onMouseOver={this.onMouseOver}
                  onMouseOut={this.onMouseOut}
                  className="selected_option"
                  data-id={selectedObj['id']}
                  key={key}
                  data-value={selectedObj['value']}
                  data-type={selectedObj['type']}
                  style={{
                    backgroundColor: '#F1F1F1',
                    color: '#A1A1A1',
                    border: '1px solid #A1A1A1',
                    float: 'left',
                    marginLeft: 5,
                    cursor: 'pointer',
                    marginBottom: 2,
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      float: 'left',
                      padding: '3px 5px',
                      color: '#676767',
                    }}
                  >
                    {label}
                  </div>
                  {!this.props.disabled && (
                    <div
                      className="close"
                      key={'close_' + key}
                      data-id={selectedObj['id']}
                      data-type={selectedObj['type']}
                      data-group={selectedObj['id']}
                      onClick={selectedObj['type'] !== 'grouped' ? this.props.onRemove : this.props.onRemoveAll}
                      style={{
                        height: '100%',
                        padding: 3,
                        visibility: 'hidden',
                        display: 'block',
                        cursor: 'pointer',
                        float: 'right',
                      }}
                    >
                      x
                    </div>
                  )}
                </div>
              )
            },
            this
          ),
          inlinedHiddenOptions = _.map(
            this.props.selectedList,
            function (option, key) {
              return (
                <input
                  key={key}
                  type="hidden"
                  disabled={this.props.disabled}
                  value={option['id']}
                  name={option['name'] || this.props.name}
                />
              )
            },
            this
          )
        return (
          <div>
            {inlinedDisplayData}
            {!this.props.shouldHide && inlinedHiddenOptions}
          </div>
        )
      },
    }),

    bookingRestrictions: React.createClass({
      getInitialState: function () {
        return {
          min: this.props.min,
          max: this.props.max,
        }
      },

      onPartyUpdateHandler: function (min, max) {
        this.setState({ min: min, max: max })
      },

      onUpdatedList: function (tables_list) {
        if (tables_list && tables_list.length === 0) {
          this.setState({ min: undefined, max: undefined })
        }
      },

      render: function () {
        var commonCss = {
          inputCss: { position: 'relative', left: 0, float: 'left', border: 0 },
          preLabelStyle: { paddingTop: '10px' },
          preInputStyle: { float: 'left', width: 'auto', height: 'auto', paddingTop: 10 },
          postLabelStyle: { margin: 0, width: 300, paddingTop: 10, paddingLeft: 20 },
        }
        return (
          <div style={{ margin: '20px 0px' }} className="inline">
            <p className="group-label" style={{ color: '#777' }}>
              <span>Booking restrictions</span>
            </p>
            <helpers.partiesSelection
              style={{ float: 'left', clear: 'right' }}
              media_url={media_url}
              min={this.state.min}
              max={this.state.max}
              onUpdateHandler={this.onPartyUpdateHandler}
            />
            <helpers.maxReservation
              inventory_count={this.props.inventory_count}
              inventory_type={this.props.inventory_type}
              guaranteeBookings={this.props.guarantee_bookings}
              media_url={this.props.media_url}
            />
            <helpers.setBookingCutoff
              media_url={this.props.media_url}
              cutoff_num={this.props.cutoff_num}
              cutoff_type={this.props.cutoff_type}
              cutoff_hour={this.props.cutoff_hour}
            />
            <helpers.restrictAvailability
              onUpdatedList={this.onUpdatedList}
              media_url={this.props.media_url}
              selected_seating_area_ids={this.props.selected_seating_area_ids}
              selected_table_ids={this.props.selected_table_ids}
              venue_seating_areas={this.props.venue_seating_areas}
              all_table_inventory_items={this.props.all_table_inventory_items}
              is_held={this.props.is_held}
              is_thefork_integration_enabled={this.props.is_thefork_integration_enabled}
              thefork_seating_area={this.props.thefork_seating_area}
              is_google_booking_enabled={this.props.is_google_booking_enabled}
              google_reserve_seating_area={this.props.google_reserve_seating_area}
            />
            <helpers.restrictPacing
              media_url={this.props.media_url}
              default_pacing={this.props.default_pacing}
              custom_pacing={this.props.custom_pacing}
              is_pacing_held={this.props.is_pacing_held}
              accessTimeType={this.props.accessTimeType}
              startTime={this.props.startTime}
              endTime={this.props.endTime}
              specificTimes={this.props.specificTimes}
            />
            <helpers.restrictSpecifyDurations
              media_url={this.props.media_url}
              duration_min={this.props.duration_min}
              duration_max={this.props.duration_max}
            />
            {window.globalInit.venueSettings.exclude_from_shift_pacing_enabled && (
              <helpers.excludeFromShiftPacing
                media_url={this.props.media_url}
                excludeFromShiftPacing={this.props.exclude_from_shift_pacing}
              />
            )}
          </div>
        )
      },
    }),

    accessTier: React.createClass({
      disablePropagationHandler: function (selectedList) {
        var time_selection_ref = 'time_selection_' + this.props.index
        this.refs[time_selection_ref].setState({ is_disabled: !selectedList.length })
      },

      alphabeticalSort: function (a, b) {
        var val1 = a.value,
          val2 = b.value
        if (val1 < val2) return -1
        if (val1 > val2) return 1
        return 0
      },

      render: function () {
        var that = this
        var selectedValues = []
        var mapCreateOptions = function (parentId, isParentSelected, item) {
          var isSelected = isParentSelected || _.contains(that.props.selectedChannels, item.value)
          if (item.children) {
            return {
              type: 'grouped',
              options: _.map(item.children, _.partial(mapCreateOptions, item.value, isSelected)),
              label: item.name,
              id: item.value,
              group: parentId,
            }
          } else {
            var option = {
              id: item.value,
              value: item.name,
              group: parentId,
            }
            if (isSelected) {
              selectedValues.push(option)
            }
            return option
          }
        }
        var options = _.map(this.props.audience_hierarchy, _.partial(mapCreateOptions, null, false))
        var tierData = (
          <span>
            <p style={{ color: '#777', fontWeight: 'bold' }}>{'TIER ' + this.props.index}</p>
            <div style={{ borderTop: '1px solid lightgrey', overflow: 'hidden', color: '#D8D9D9' }}></div>
          </span>
        )
        var body = (
          <div className="inline" style={{ margin: '10px 0px' }}>
            <p className="group-label">Who can book?</p>
            <helpers.inlinedDropDown
              options={options}
              name={this.props.name}
              key={'access-tier_' + this.props.index}
              propagationHandler={this.disablePropagationHandler}
              label="access-tier"
              initialList={selectedValues}
              groupIdsRequiringManualAllSelection={['THIRD_PARTY_CHANNELS']}
              startCollapsed={true}
              postActionFunc={helpers.displayHideWaitlistWarning}
              disabled={this.props.disableEdit}
            />
          </div>
        )

        return (
          <div className="inline" style={{ paddingTop: 10 }}>
            {tierData}
            {body}
            <div className="inline">
              <helpers.accessTimeSelection
                include_title
                ref={'time_selection_' + this.props.index}
                media_url={this.props.media_url}
                name_1={'start_num_' + this.props.index}
                name_2={'start_type_' + this.props.index}
                name_3={'start_hour_' + this.props.index}
                disabled={!this.props.selectedChannels.length}
                num={this.props.num}
                type={this.props.type}
                hour={this.props.hour}
                selectionType="ACCESS_TIER"
                disableEdit={this.props.disableEdit}
              />
            </div>
          </div>
        )
      },
    }),

    setBookingCutoff: React.createClass({
      getInitialState: function () {
        return {
          disabled: !this.props.cutoff_type,
        }
      },

      onCheckboxClick: function (e) {
        if (this.refs.booking_time_selection) {
          this.refs.booking_time_selection.setState({
            is_disabled: !$(e.currentTarget).is(':checked'),
          })
        } else {
          this.setState({
            disabled: !$(e.currentTarget).is(':checked'),
          })
        }
      },

      render: function () {
        return (
          <div>
            <div className="inline">
              <modules.formElements.create
                type="checkbox"
                name="booking_cutoff_restriction"
                checked={!this.state.disabled}
                onClickHandler={this.onCheckboxClick}
                label="Set a booking cut-off time"
                inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                labelStyle={{ margin: 0 }}
                style={{ clear: 'both', backgroundColor: '#FFF', marginTop: 10, float: 'left', fontFamily: 'Roboto' }}
              />
            </div>
            {!this.state.disabled ? (
              <div className="inline" style={{ paddingLeft: 20 }}>
                <helpers.accessTimeSelection
                  ref="booking_time_selection"
                  disabled={this.state.disabled}
                  media_url={this.props.media_url}
                  name_1="cutoff_num"
                  name_2="cutoff_type"
                  name_3="cutoff_hour"
                  allowMinutes
                  num={this.props.cutoff_num}
                  type={this.props.cutoff_type}
                  hour={this.props.cutoff_hour}
                  selectionType="BOOKING_CUTOFF"
                />
              </div>
            ) : undefined}
          </div>
        )
      },
    }),

    setIsHeld: React.createClass({
      getInitialState: function () {
        return {
          checked: this.props.checked,
        }
      },

      onCheckboxClick: function (e) {
        this.setState({
          checked: $(e.currentTarget).is(':checked'),
        })
      },

      render: function () {
        return (
          <div>
            <div className="inline">
              <modules.formElements.create
                type="checkbox"
                name="is_held"
                value="1"
                checked={this.state.checked}
                onClickHandler={this.onCheckboxClick}
                label="Treat seating area(s) and/or table(s) as Blocked"
                inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                labelStyle={{ margin: 0 }}
                style={{ clear: 'both', backgroundColor: '#FFF', marginTop: 10, float: 'left', fontFamily: 'Roboto' }}
              />
            </div>
          </div>
        )
      },
    }),

    setIsPacingHeld: React.createClass({
      getInitialState: function () {
        return {
          checked: this.props.checked,
        }
      },

      onCheckboxClick: function (e) {
        this.setState({
          checked: $(e.currentTarget).is(':checked'),
        })
      },

      render: function () {
        return (
          <div>
            <div className="inline" style={{ width: '100%' }}>
              <modules.formElements.create
                type="checkbox"
                name="is_pacing_held"
                value="1"
                checked={this.state.checked}
                onClickHandler={this.onCheckboxClick}
                label="Reduce total pacing available to account for these potential reservations"
                inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                postLabelStyle={{ whiteSpace: 'pre-wrap', width: 340 }}
                style={{ clear: 'both', backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto' }}
              />
            </div>
          </div>
        )
      },
    }),

    accessShiftCategorySelection: React.createClass({
      render: function () {
        return (
          <span>
            <helpers.accessruleTimeIntervals
              access_time_type={this.props.access_time_type}
              specific_times_display={this.props.specificTimes}
              startTime={this.props.startTime}
              endTime={this.props.endTime}
              restrictToShifts={this.props.restrictToShifts}
              shift_categories={this.props.selected_shift_categories}
              media_url={this.props.media_url}
              host_page={this.props.host_page}
            />
          </span>
        )
      },
    }),

    maxReservation: React.createClass({
      getInitialState: function () {
        return {
          disabled: !this.props.inventory_type || this.props.inventory_type === 'UNLIMITED',
          guaranteeBookings: this.props.guaranteeBookings,
        }
      },

      onCheckboxClick: function (e) {
        this.setState({
          disabled: !$(e.currentTarget).is(':checked'),
        })
      },

      onGuaranteeCheckboxClick: function (e) {
        this.setState({
          guaranteeBookings: $(e.currentTarget).is(':checked'),
        })
      },

      render: function () {
        return (
          <div>
            <div className="inline">
              <modules.formElements.create
                checked={!this.state.disabled}
                type="checkbox"
                name="max_reservation_restriction"
                onClickHandler={this.onCheckboxClick}
                label="Set a maximum reservation or cover limit per day"
                inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                labelStyle={{ margin: 0 }}
                style={{ clear: 'both', marginTop: 10, backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto' }}
              />
            </div>
            {!this.state.disabled ? (
              <div className="inline" style={{ margin: '10px 0', paddingLeft: 20 }}>
                <modules.formElements.create
                  type="number"
                  min="1"
                  disabled={this.state.disabled}
                  value={this.props.inventory_count}
                  name="inventory_count"
                  inputCss={{ padding: '6px 8px', width: 70 }}
                  preInputStye={{ width: '8em' }}
                  style={{ clear: 'both', backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto' }}
                />
                <modules.formElements.create
                  type="select"
                  disabled={this.state.disabled}
                  value={this.props.inventory_type}
                  media_url={this.props.media_url}
                  name="inventory_type"
                  options={{ RESERVATIONS: 'reservations', COVERS: 'covers' }}
                  preInputStyle={{ width: '8em' }}
                  inputCss={{ padding: '6px 8px' }}
                  style={{ backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto', clear: 'right' }}
                />
                <div>
                  <modules.formElements.create
                    checked={this.state.guaranteeBookings}
                    type="checkbox"
                    name="guarantee_bookings"
                    value="1"
                    onClickHandler={this.onGuaranteeCheckboxClick}
                    label="Guarantee Bookings"
                    inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                    labelStyle={{ margin: 0 }}
                    style={{ clear: 'both', marginTop: 10, backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto' }}
                  />
                </div>
              </div>
            ) : undefined}
          </div>
        )
      },
    }),

    restrictAvailability: React.createClass({
      getInitialState: function () {
        return {
          disabled: _.isEmpty(this.props.selected_table_ids) && _.isEmpty(this.props.selected_seating_area_ids),
        }
      },

      onCheckboxClick: function (e) {
        if (this.refs.tables_areas_dropdown) {
          this.refs.tables_areas_dropdown.setState({
            is_disabled: !$(e.currentTarget).is(':checked'),
          })
        }
        this.setState({
          disabled: !$(e.currentTarget).is(':checked'),
        })
      },

      onClick: function () {
        if (this.props.disabled) {
          return
        }
        $(this.getDOMNode()).find('.utildrop').css('display', 'block')
      },

      render: function () {
        var selected_table_objects = _.filter(
            this.props.all_table_inventory_items,
            function (t) {
              return _.contains(this.props.selected_table_ids, t.id)
            },
            this
          ),
          selected_seating_area_objects = _.filter(
            this.props.venue_seating_areas,
            function (t) {
              return _.contains(this.props.selected_seating_area_ids, t.id)
            },
            this
          )

        var table_options = _.map(this.props.all_table_inventory_items, function (item) {
            return {
              type: 'table',
              value: item['item_code'],
              id: item['id'],
              name: 'table_ids',
            }
          }),
          area_options = _.map(this.props.venue_seating_areas, function (item) {
            return {
              type: 'area',
              value: item['name'],
              id: item['id'],
              name: 'seating_area_ids',
            }
          }),
          selected_table_options = _.map(selected_table_objects, function (item) {
            return {
              type: 'table',
              value: item['item_code'],
              id: item['id'],
              name: 'table_ids',
            }
          }),
          selected_area_options = _.map(selected_seating_area_objects, function (item) {
            return {
              type: 'area',
              value: item['name'],
              id: item['id'],
              name: 'seating_area_ids',
            }
          })

        var options = table_options.concat(area_options)
        // If any changes are made here, please also update:
        // * TheForkSeatingArea in bookingaccessbase.py
        // * theForkSeatingOptions in integrationOptions.ts
        var thefork_seating_options = {
          INSIDE: 'Inside',
          OUTSIDE: 'Outside',
          TERRACE: 'Terrace',
          ROOFTOP: 'Rooftop',
          PATIO: 'Patio',
          GARDEN: 'Garden',
          BAR: 'Bar',
          CHEFS_TABLE: "Chef's Table",
          CHILDRENS_AREA: "Children's Area",
          PRIVATE_DINING_ROOM: 'Private Dining Room',
          UPSTAIRS: 'Upstairs',
          DOWNSTAIRS: 'Downstairs',
          BISTRO: 'Bistro',
          LOUNGE: 'Lounge',
          WINTER_GARDEN: 'Winter Garden',
          SEASIDE: 'Seaside',
        }
        // If any changes are made here, please also update:
        // * GoogleReserveSeatingArea in bookingaccessbase.py
        // * googleReserveSeatingOptions in integrationOptions.ts
        var google_reserve_seating_options = {
          NOT_USING: 'No Label / Not Using',
          BAR: 'Bar',
          COUNTER: 'Counter',
          DINING_ROOM: 'Dining Room',
          GARDEN: 'Garden',
          HIGH_TOP: 'High-Top',
          INDOOR: 'Indoor',
          MAIN_DINING_ROOM: 'Main Dining Room',
          OUTDOOR: 'Outdoor',
          STANDARD: 'Standard',
          TERRACE: 'Terrace',
          HEADER: { disabled: true, value: '----- Less Common -----' },
          AFTERNOON_TEA: 'Afternoon Tea',
          BAR_SEATING: 'Bar Seating',
          BEACH: 'Beach',
          BISTRO: 'Bistro',
          BUFFET: 'Buffet',
          CHEFS_TABLE: "Chef's Table",
          CHEFS_COUNTER: "Chef's Counter",
          COCKTAIL_BAR: 'Cocktail Bar',
          COUNTER_SEATING: 'Counter Seating',
          COUNTER_SEATS: 'Counter Seats',
          DINING_TABLE: 'Dining Table',
          DOG_FRIENDLY: 'Dog-Friendly',
          DOWNSTAIRS: 'Downstairs',
          DRINKS_TABLE: 'Drinks Table',
          FIRST_FLOOR: 'First Floor',
          GROUND_FLOOR: 'Ground Floor',
          INDOOR_DINING: 'Indoor Dining',
          INDOOR_RESERVATION: 'Indoor Reservation',
          INDOOR_SEATING: 'Indoor Seating',
          INDOOR_TABLE: 'Indoor Table',
          INDOOR_TABLES: 'Indoor Tables',
          KITCHEN_COUNTER: 'Kitchen Counter',
          LOUNGE: 'Lounge',
          MAIN_DINING: 'Main Dining',
          NON_SMOKING_SECTION: 'Non-Smoking Section',
          OUTDOOR_CABIN: 'Outdoor Cabin',
          OUTDOOR_DINING: 'Outdoor Dining',
          OUTDOOR_HEATED: 'Outdoor Heated',
          OUTDOOR_NOT_HEATED: 'Outdoor Not Heated',
          OUTDOOR_PATIO: 'Outdoor Patio',
          OUTDOOR_SEATING: 'Outdoor Seating',
          OUTDOOR_TERRACE: 'Outdoor Terrace',
          PATIO: 'Patio',
          POOLSIDE: 'Poolside',
          PRE_THEATRE: 'Pre-Theatre',
          PRIVATE_DINING_ROOM: 'Private Dining Room',
          RESTAURANT: 'Restaurant',
          ROOFTOP: 'Rooftop',
          SALLE_INTERIEURE: 'Salle Intérieure',
          SALON: 'Salón',
          SALONES: 'Salónes',
          SECOND_FLOOR: 'Second Floor',
          SMOKING_SECTION: 'Smoking Section',
          SUSHI_BAR: 'Sushi Bar',
          TERRAZA: 'Terraza',
          TERRAZZA: 'Terrazza',
          UPSTAIRS: 'Upstairs',
          WINDOW_COUNTER: 'Window Counter',
          WINDOW_HIGH_TOP: 'Window High Top',
        }

        return (
          <div>
            <div className="inline">
              <modules.formElements.create
                type="checkbox"
                name="access_type_location"
                checked={!this.state.disabled}
                onClickHandler={this.onCheckboxClick}
                label="Restrict availability to specific table(s) or area(s)"
                inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                labelStyle={{ margin: 0 }}
                style={{ clear: 'both', backgroundColor: '#FFF', marginTop: 10, float: 'left', fontFamily: 'Roboto' }}
              />
            </div>
            {!this.state.disabled ? (
              <div style={{ paddingLeft: 20 }}>
                <helpers.inlinedDropDown
                  ref="tables_areas_dropdown"
                  options={options}
                  propagationHandler={this.props.onUpdatedList}
                  initialList={selected_table_options.concat(selected_area_options)}
                  disabled={this.props.disabled}
                  selectedObjs={[]}
                />
                <helpers.setIsHeld checked={this.props.is_held} />
                {this.props.is_google_booking_enabled && (
                  <div style={{ float: 'left', clear: 'both', marginTop: '8px', marginBottom: '8px' }}>
                    <modules.formElements.create
                      type="select"
                      value={this.props.google_reserve_seating_area}
                      options={google_reserve_seating_options}
                      media_url={this.props.media_url}
                      labelText="Google Reserve Seating Area Label"
                      key="google_reserve_seating_area"
                      name="google_reserve_seating_area"
                      style={{ fontFamily: 'Roboto', fontStyle: 'italic' }}
                      preLabelStyle={{ fontSize: 11, fontWeight: 'normal' }}
                    />
                  </div>
                )}
                {this.props.is_thefork_integration_enabled && (
                  <div style={{ float: 'left', clear: 'both', marginTop: '8px', marginBottom: '8px' }}>
                    <modules.formElements.create
                      type="select"
                      value={(this.props.thefork_seating_area || '').toUpperCase()}
                      options={thefork_seating_options}
                      media_url={this.props.media_url}
                      labelText="TheFork Area Type"
                      key="thefork_seating_area"
                      name="thefork_seating_area"
                      style={{ fontFamily: 'Roboto', fontStyle: 'italic' }}
                      preLabelStyle={{ fontSize: 11, fontWeight: 'normal' }}
                    />
                  </div>
                )}
              </div>
            ) : undefined}
          </div>
        )
      },
    }),

    restrictPacing: React.createClass({
      getInitialState: function () {
        return {
          default_pacing: this.props.default_pacing,
          disabled: this.props.default_pacing == null && _.isEmpty(this.props.custom_pacing),
        }
      },

      onCheckboxClick: function (e) {
        this.setState({
          disabled: !$(e.currentTarget).is(':checked'),
        })
      },

      onClick: function () {
        if (this.props.disabled) {
          return
        }
        $(this.getDOMNode()).find('.utildrop').css('display', 'block')
      },

      onChangeHandler: function (e) {
        this.setState({
          default_pacing: $(e.target).val(),
        })
      },

      render: function () {
        return (
          <div>
            <div className="inline">
              <modules.formElements.create
                type="checkbox"
                name="access_type_restrict_pacing"
                checked={!this.state.disabled}
                onClickHandler={this.onCheckboxClick}
                label="Restrict pacing at time intervals"
                inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                labelStyle={{ margin: 0 }}
                style={{ clear: 'both', backgroundColor: '#FFF', marginTop: 10, float: 'left', fontFamily: 'Roboto' }}
              />
            </div>
            <div className="inline" style={{ paddingLeft: 20, display: this.state.disabled ? 'none' : 'block' }}>
              <modules.formElements.create
                value={this.state.default_pacing || 0}
                style={{ fontFamily: 'Roboto', fontStyle: 'italic' }}
                labelText="Max number of covers per seating interval"
                onChangeHandler={this.onChangeHandler}
                min="0"
                name="default_pacing"
                preLabelStyle={{ fontSize: 11, fontWeight: 'normal' }}
                ref="default_pacing_input"
                disabled={this.state.disabled}
                type="number"
                inputCss={{ width: 80, padding: '6px 8px' }}
              />
              <helpers.customPacing
                customPacing={this.props.custom_pacing}
                default_pacing={this.state.default_pacing}
                accessTimeType={this.props.accessTimeType}
                startTime={this.props.startTime}
                endTime={this.props.endTime}
                specificTimes={this.props.specificTimes}
                media_url={this.props.media_url}
              />
              <helpers.setIsPacingHeld checked={this.props.is_pacing_held} />
            </div>
          </div>
        )
      },
    }),

    customPacing: React.createClass({
      getInitialState: function () {
        var startTime = this.props.startTime,
          endTime = this.props.endTime
        return {
          show: !_.isEmpty(this.props.customPacing),
          accessTimeType: this.props.accessTimeType ? this.props.accessTimeType : 'ALL',
          startTime: startTime,
          endTime: endTime,
          specificTimes: this.props.specificTimes,
        }
      },

      onClickHandler: function (e) {
        this.setState({
          show: !this.state.show,
        })
      },

      componentDidMount: function () {
        helpers.customPacingComponent = this
      },

      render: function () {
        var customPacingByTime = helpers.convertCustomPacingToTimePacing(this.props.customPacing),
          imageUri = this.props.media_url + 'images/icons/',
          shutImageUrl = 'url(' + imageUri + 'blue-arrow-right.png)',
          openImageUrl = 'url(' + imageUri + 'blue-arrow-down.png)',
          imageUrl = this.state.show ? openImageUrl : shutImageUrl,
          availableTimes =
            this.state.accessTimeType === 'ALL'
              ? helpers.getAvailableTimes()
              : this.state.accessTimeType === 'SPECIFIC'
              ? _.object(this.state.specificTimes, this.state.specificTimes)
              : helpers.getAvailableTimes(this.state.startTime, this.state.endTime, true),
          timeItems = _.map(
            availableTimes,
            function (timeslot, index) {
              var selectedPace = customPacingByTime[timeslot]
              return (
                <helpers.customPacingUnit
                  default_pacing={this.props.default_pacing}
                  selected={selectedPace !== undefined}
                  pace={selectedPace}
                  index={index}
                  timeslot={timeslot}
                  key={index}
                />
              )
            },
            this
          )
        return (
          <div className="inline" style={{ marginTop: 10, marginBottom: 10 }}>
            <div style={{ width: 370, marginBottom: 3 }}>
              <a href="#" onClick={this.onClickHandler}>
                <div
                  style={{
                    float: 'left',
                    backgroundImage: imageUrl,
                    backgroundSize: 15,
                    width: 15,
                    marginTop: 1,
                    height: 14,
                  }}
                ></div>
                &nbsp;Or, set custom pacing per seating interval
              </a>
            </div>
            {this.state.show ? timeItems : undefined}
          </div>
        )
      },
    }),

    customPacingKeysSorted: function (customPacing) {
      var sortOrdersToMinutes = _.map(Object.keys(customPacing), function (mins) {
        var minutesSinceMidnight = parseInt(mins, 10)
        var midnightSortOrder = minutesSinceMidnight / 15
        var sortOrder = midnightSortOrder - 4 * Pmp.Manager.Global.start_of_day_hour
        if (sortOrder < 0) {
          sortOrder = 95 + sortOrder // Wrap-around to end of day
        }
        return [sortOrder, mins]
      })
      sortOrdersToMinutes.sort()
      return _.map(sortOrdersToMinutes, function (som) {
        return som[1]
      })
    },

    convertCustomPacingToTimePacing: function (customPacing) {
      return _.object(
        _.map(
          customPacing,
          function (pacing, mins) {
            return [modules.weekViewManager.getMinutesDisplay(mins), pacing]
          },
          this
        )
      )
    },

    customPacingUnit: React.createClass({
      getInitialState: function () {
        return { selected: this.props.selected }
      },
      resetState: function (e) {
        this.setState({
          selected: $(e.currentTarget).is(':checked'),
        })
      },
      shouldComponentUpdate: function (nextProps, nextState) {
        return !this.state.selected || this.state.selected !== nextState.selected
      },
      render: function () {
        return (
          <div className="inline" style={{ width: '100%', margin: '3px 3px 0', borderBottom: '1px solid #eee' }}>
            <modules.formElements.create
              type="checkbox"
              name={'custom_pacing_time'}
              id={this.props.timeslot}
              value={this.props.timeslot}
              index={this.props.index}
              key={this.props.index}
              label={this.props.timeslot}
              checked={this.state.selected}
              onClickHandler={this.resetState}
              preLabelStyle={{ float: 'left', paddingTop: '10px' }}
              style={{ clear: 'initial', backgroundColor: '#FFF', margin: 3 }}
              preInputStyle={{ margin: 0, padding: 3 }}
              postLabelStyle={{ top: '-1px' }}
              labelStyle={{ margin: 0 }}
            />
            <span
              style={{
                clear: 'initial',
                float: 'right',
                fontFamily: 'Roboto',
                marginTop: 5,
                color: '#aaa',
              }}
            >
              covers
            </span>
            <modules.formElements.create
              type="number"
              index={this.props.index}
              key={'input' + this.props.index}
              value={this.props.pace !== undefined ? this.props.pace : this.props.default_pacing}
              disabled={!this.state.selected}
              name={'custom_pacing_value'}
              inputCss={{ width: 30, height: 11 }}
              style={{ backgroundColor: '#FFF', float: 'right', fontFamily: 'Roboto', marginRight: 5 }}
            />
          </div>
        )
      },
    }),

    restrictSpecifyDurations: React.createClass({
      getInitialState: function () {
        return {
          disabled: !this.props.duration_min && !this.props.duration_max,
        }
      },

      onCheckboxClick: function (e) {
        this.setState({
          disabled: !$(e.currentTarget).is(':checked'),
        })
      },

      render: function () {
        var options = helpers.getDurationOptions()
        return (
          <div>
            <div className="inline">
              <modules.formElements.create
                type="checkbox"
                name="restrict_specify_durations"
                checked={!this.state.disabled}
                onClickHandler={this.onCheckboxClick}
                label="Client must specify duration (ignore shift party size defaults)"
                inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                labelStyle={{ margin: 0 }}
                style={{ clear: 'both', backgroundColor: '#FFF', marginTop: 10, float: 'left', fontFamily: 'Roboto' }}
              />
            </div>
            {!this.state.disabled ? (
              <div className="inline" style={{ width: '100%', paddingLeft: 20 }}>
                <modules.formElements.create
                  type="select"
                  value={this.props.duration_min || 15}
                  options={options}
                  media_url={this.props.media_url}
                  labelText="Duration min"
                  key="duration_min"
                  name="duration_min"
                  style={{ fontFamily: 'Roboto', fontStyle: 'italic' }}
                  preLabelStyle={{ fontSize: 11, fontWeight: 'normal' }}
                  disabled={this.state.disabled}
                />
                <modules.formElements.create
                  type="select"
                  value={this.props.duration_max || 120}
                  options={options}
                  media_url={this.props.media_url}
                  labelText="Duration max"
                  key="duration_max"
                  name="duration_max"
                  style={{ fontFamily: 'Roboto', fontStyle: 'italic' }}
                  preLabelStyle={{ fontSize: 11, fontWeight: 'normal' }}
                  disabled={this.state.disabled}
                />
              </div>
            ) : undefined}
          </div>
        )
      },
    }),

    excludeFromShiftPacing: React.createClass({
      getInitialState: function () {
        return {
          excludeFromShiftPacing: this.props.excludeFromShiftPacing,
        }
      },

      onCheckboxClick: function (e) {
        this.setState({
          excludeFromShiftPacing: $(e.currentTarget).is(':checked'),
        })
      },

      render: function () {
        return (
          <div>
            <div className="inline">
              <modules.formElements.create
                type="checkbox"
                name="exclude_from_shift_pacing"
                checked={this.state.excludeFromShiftPacing}
                value="1"
                onClickHandler={this.onCheckboxClick}
                label={
                  <span>
                    Do not count reservations booked through this Access Rule towards
                    <br />
                    Shift Pacing
                  </span>
                }
                inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                labelStyle={{ margin: 0 }}
                style={{ clear: 'both', backgroundColor: '#FFF', marginTop: 10, float: 'left', fontFamily: 'Roboto' }}
              />
            </div>
          </div>
        )
      },
    }),

    accessRuleDateIntervals: React.createClass({
      componentDidUpdate: function () {
        this.postRenderUpdate()
      },

      postRenderUpdate: function () {
        helpers.applyCalendarBehavior('#rule-start-date-selector', this.props.startDay)
        helpers.applyCalendarBehavior(
          '#rule-end-date-selector',
          this.props.endDay ? this.props.endDay : this.props.startDay,
          undefined,
          this.props.startDay
        )
        $(this.getDOMNode()).find('#rule-start-date-selector').prop('disabled', this.props.isEditMode)
        $(this.getDOMNode())
          .find('#rule-end-date-selector')
          .prop('disabled', this.props.isEditMode || this.props.isIndefinite)
        $(this.getDOMNode()).find('#rule-end-date-selector-submit').prop('disabled', this.props.isIndefinite)

        if (this.props.isIndefinite) {
          $('#rule-end-date-selector').val('--')
          $('#rule-end-date-selector-submit').val('--')
        }
      },

      componentDidMount: function () {
        this.postRenderUpdate()
      },

      onClickIndefinitely: function (e) {
        var $rule_end_date_selector = $('#rule-end-date-selector')
        var $rule_end_date_selector_submit = $('#rule-end-date-selector-submit')
        if ($(e.currentTarget).is(':checked')) {
          $rule_end_date_selector.prop('disabled', true)
          $rule_end_date_selector.val('--')
          $rule_end_date_selector_submit.prop('disabled', true)
          $rule_end_date_selector_submit.val('--')
        } else {
          $rule_end_date_selector.prop('disabled', false)
          $rule_end_date_selector_submit.prop('disabled', false)
        }
      },
      // TODO validation on OT ERB channel and party size/table type rule
      // ie only allow table type rules if OT ERB channel is selected
      render: function () {
        return (
          <div>
            <div className="inline" style={{ paddingTop: 10 }}>
              <div className="form-element cal text">
                <p className="group-label" style={{ color: '#777', margin: '10px 0' }}>
                  Start date
                  <span className="required">*</span>
                </p>
                <label htmlFor="rule-date-selector">
                  <p className="input" style={{ position: 'relative' }}>
                    <input readOnly="true" id="rule-start-date-selector" type="text" name="start_date_cal" srValidate="datepicker_date" />
                    <input id="rule-start-date-selector-submit" type="hidden" name="start_date" />
                    <img src={this.props.calendar_image} />
                  </p>
                </label>
                <p className="validator" srValidateError>
                  Please select a start date
                </p>
              </div>
              <div className="form-element cal text">
                <p className="group-label" style={{ color: '#777', margin: '10px 0' }}>
                  End date
                  <span className="required">*</span>
                </p>
                <label htmlFor="rule-end-date-selector">
                  <p className="input" style={{ position: 'relative' }}>
                    <input readOnly="true" id="rule-end-date-selector" name="end_date_cal" type="text" srValidate="datepicker_date" />
                    <input id="rule-end-date-selector-submit" type="hidden" name="end_date" />
                    <img src={this.props.calendar_image} />
                  </p>
                </label>
                <p className="validator" srValidateError>
                  Please select a end date
                </p>
              </div>
            </div>
            <div className="inline">
              <modules.formElements.create
                type="checkbox"
                onClickHandler={this.onClickIndefinitely}
                checked={!!this.props.isIndefinite}
                name="rule-indefinitely"
                label="continue indefinite"
                id="rule_indefinite"
                preInputStyle={{ backgroundColor: '#FFF' }}
                style={{ paddingLeft: 200 }}
              />
            </div>
          </div>
        )
      },
    }),

    paymentAndPolicyTab: React.createClass({
      propTypes: {
        media_url: React.PropTypes.string.isRequired,
        payment_setup: React.PropTypes.bool.isRequired,
        default_policy: React.PropTypes.string.isRequired,
        default_cancellation_policy: React.PropTypes.string.isRequired,
        tax_rate: React.PropTypes.number,
        currency_symbol: React.PropTypes.string.isRequired,
        initial_use_shift_payment_and_policy: React.PropTypes.bool.isRequired,
        initial_require_credit_card: React.PropTypes.bool.isRequired,
        initial_party_size_min: React.PropTypes.number,
        initial_payment_rule: React.PropTypes.string,
        initial_cost: React.PropTypes.number,
        initial_charge_type: React.PropTypes.string,
        initial_apply_tax_rate: React.PropTypes.bool,
        initial_gratuity: React.PropTypes.number,
        initial_policy_type: React.PropTypes.string,
        initial_policy: React.PropTypes.string,
        initial_cancellation_policy_type: React.PropTypes.string,
        initial_cancellation_policy: React.PropTypes.string,
        auto_charge_type: React.PropTypes.string,
        auto_charge_amount: React.PropTypes.number,
        auto_charge_amount_in_cents: React.PropTypes.number,
        auto_charge_amount_type: React.PropTypes.string,
        auto_cutoff_num: React.PropTypes.number,
        auto_cutoff_type: React.PropTypes.string,
        auto_cutoff_hour: React.PropTypes.string,
        cancel_cutoff_num: React.PropTypes.number,
        cancel_cutoff_type: React.PropTypes.string,
        cancel_cutoff_hour: React.PropTypes.string,
        can_save_card: React.PropTypes.bool,
        ignore_descriptions_for_3p_bookers: React.PropTypes.bool,
        venue_settings: React.PropTypes.object,
        venue_id: React.PropTypes.string,
      },

      getInitialState: function () {
        return {
          use_shift_payment_and_policy: this.props.initial_use_shift_payment_and_policy,
          ignore_cc_for_3p_bookers: this.props.ignore_cc_for_3p_bookers,
        }
      },

      clickUseShift: function (event) {
        this.setState({ use_shift_payment_and_policy: !this.state.use_shift_payment_and_policy })
      },

      clickIgnoreCCForBookers: function (event) {
        this.setState({ ignore_cc_for_3p_bookers: !this.state.ignore_cc_for_3p_bookers })
      },

      paymentsNotSetupContent: function () {
        return (
          <div
            style={{
              borderRadius: '4px',
              clear: 'both',
              display: 'flex',
              padding: '1em',
              marginTop: '1em',
              backgroundColor: 'rgba(62, 147, 209, 0.20)',
              fontSize: '11px',
            }}
          >
            <div style={{ flexGrow: '1' }}>
              <div>
                <div style={{ fontWeight: 'bold' }}>Get Paid Through Your Reservation & Events Widget</div>
                <div style={{ color: '#425466', marginTop: '2px' }}>Connect a processor in minutes to enable online payments.</div>
              </div>
            </div>
            <div className="button">
              <a
                style={{ minWidth: 'fit-content', padding: '0 1em', fontSize: '12px', lineHeight: '2.5em !important', height: '2.5em' }}
                href={`/manager2/${window.globalInit.venueUrlKey}/settings/payment-integration/view`}
                target="_blank"
              >
                Connect
              </a>
            </div>
          </div>
        )
      },

      render: function () {
        return (
          <div className="section">
            {!this.props.payment_setup && this.paymentsNotSetupContent()}
            <modules.formElements.create
              type="checkbox"
              onClickHandler={this.clickUseShift}
              checked={this.state.use_shift_payment_and_policy}
              name="use_shift_payment_and_policy"
              value={1}
              label="Follow payment and policy configured for this shift"
              id="use-shift-payment-and-policy"
              inputCss={{ border: 0 }}
              preInputStyle={{ backgroundColor: '#FFF' }}
              postLabelStyle={{ color: '#777' }}
            />
            <modules.formElements.create
              type="checkbox"
              onClickHandler={this.clickIgnoreCCForBookers}
              checked={this.state.ignore_cc_for_3p_bookers}
              name="ignore_cc_for_3p_bookers"
              value={1}
              label={
                <span>
                  Allow this rule to be booked on channels that do not support <br /> credit card holds
                </span>
              }
              id="ignore-cc-for-3p-bookers"
              inputCss={{ border: 0 }}
              style={{ marginBottom: 20 }}
              preInputStyle={{ backgroundColor: '#FFF' }}
              postLabelStyle={{ color: '#777' }}
            />
            {this.state.use_shift_payment_and_policy ? undefined : (
              <PaymentAndPolicy
                {...this.props}
                changeBookingPolicyID={changeBookingPolicyID}
                changePolicyType={changePolicyType}
                changeCancellationPolicyID={changeCancellationPolicyID}
                changeCancellationPolicyType={changeCancellationPolicyType}
              />
            )}
          </div>
        )
      },
    }),

    publicPhotoUpload: React.createClass({
      propTypes: {
        public_photo: React.PropTypes.string,
      },

      getInitialState: function () {
        return {
          public_photo: this.props.public_photo,
        }
      },

      imgUploadHandler: function (e, data) {
        this.setState({ public_photo: '/.h/download/' + data.blob_key })
      },

      componentDidMount: function () {
        Uploader.basic('#public-description-bg', this.imgUploadHandler, false, false, '/widget-logo-key')
      },

      onDeleteHandler: function () {
        this.setState({ public_photo: '' })
      },

      render: function () {
        return (
          <div className="form-element">
            <p className="group-label" style={{ color: '#777', margin: '10px 0' }}>
              Public Photo
            </p>
            <div className="inline photo-uploader">
              {this.state.public_photo ? (
                <div
                  onClick={this.onDeleteHandler}
                  style={{
                    zIndex: 20,
                    position: 'relative',
                    width: 20,
                    height: 20,
                    left: 380,
                    top: 20,
                    cursor: 'pointer',
                    backgroundSize: 'cover',
                    backgroundImage: 'url(' + this.props.media_url + 'images/eventmanager/delete.png)',
                  }}
                ></div>
              ) : null}
              <Common.PhotoUpload
                id="public-description-bg"
                media_url={this.props.media_url}
                photoImageSourceStyle={{
                  height: this.state.public_photo ? 'auto' : 200,
                  top: 10,
                  position: 'absolute',
                  left: this.state.public_photo ? 0 : 100,
                  width: this.state.public_photo ? '100%' : 200,
                }}
                teaserStyle={{
                  zIndex: 2,
                  color: '#347baf',
                  position: 'absolute',
                  width: 400,
                  textAlign: 'center',
                  cursor: 'pointer',
                  pointerEvents: 'none',
                }}
                inputImageSource={{
                  height: 200,
                  width: 400,
                  display: 'block',
                  left: 0,
                  opacity: 0,
                  padding: 0,
                  position: 'absolute',
                  top: 0,
                  cursor: 'pointer',
                }}
                thumbUploadStyle={{
                  height: 200,
                  width: 400,
                  borderRadius: 0,
                  overflow: 'hidden',
                  border: '1px dashed lightgrey',
                  position: 'relative',
                  marginBottom: 15,
                }}
                img={this.state.public_photo}
              />
              <input type="hidden" name="public_photo" value={this.state.public_photo} />
            </div>
          </div>
        )
      },
    }),

    publicDescriptionTab: React.createClass({
      propTypes: {
        media_url: React.PropTypes.string.isRequired,
        public_time_slot_description: React.PropTypes.string,
        public_description_title: React.PropTypes.string,
        public_photo: React.PropTypes.string,
        public_long_form_description: React.PropTypes.string,
        ignore_descriptions_for_3p_bookers: React.PropTypes.bool,
        selectedAutomaticUpsells: React.PropTypes.array,
        upsellsToInventory: React.PropTypes.object,
        upsellCategories: React.PropTypes.object,
        experiences: React.PropTypes.object,
        selected_experience_key: React.PropTypes.string,
      },

      getInitialState: function () {
        const includeAutomaticUpsell = !_.isEmpty(this.props.selectedAutomaticUpsells)
        return {
          addAutomaticUpsells: includeAutomaticUpsell,
        }
      },

      handleOnClick() {
        this.setState({ addAutomaticUpsells: true })
      },

      handleOffClick() {
        this.setState({ addAutomaticUpsells: false })
      },

      componentWillMount: function () {
        this.selected_experience_key = this.props.selected_experience_key || 'None'
        this.experience_options = _.reduce(
          this.props.experiences,
          (accum, experience) => {
            if (experience.offer_type !== 'EVENT') {
              accum[experience.id] = `${experience.name} (${experience.status})`
            }
            return accum
          },
          { None: '--select--' }
        )
      },

      componentDidMount: function () {
        this.quill = new Quill('#public_long_form_description', {
          modules: {
            toolbar: [['bold', 'italic', 'underline']],
          },
          placeholder: 'Input text',
          theme: 'snow',
        })
      },

      render: function () {
        var public_long_form_description = this.props.public_long_form_description

        function publicLongDescriptionMarkup() {
          return { __html: public_long_form_description }
        }

        return (
          <div className="section">
            <div className="inline select-experience">
              <modules.formElements.create
                type="select"
                onChangeHandler={this.onExperienceSelectorChange}
                value={this.selected_experience_key}
                name="experience_id"
                labelText="Link to Offer"
                preLabelStyle={{ color: '#777', margin: '10px 0 10px' }}
                prelabel="Link to Offer"
                options={this.experience_options}
                media_url={this.props.media_url}
                inputCss={{ padding: '6px 8px' }}
                style={{ backgroundColor: '#FFF', float: 'left', fontFamily: 'Lato', clear: 'right' }}
              />
            </div>
            <div className="inline public-time-slot-description">
              <modules.formElements.create
                name="public_time_slot_description"
                maxlength="20"
                size="20"
                labelText="Time Slot Description"
                preLabelStyle={{ color: '#777', margin: '10px 0 10px' }}
                prelabel="Time Slot Description"
                value={this.props.public_time_slot_description}
              />
            </div>
            <div className="inline public-description-title">
              <modules.formElements.create
                name="public_description_title"
                maxlength="20"
                size="20"
                labelText="Title"
                preLabelStyle={{ color: '#777', margin: '10px 0 10px' }}
                prelabel="Title"
                value={this.props.public_description_title}
              />
            </div>
            <helpers.publicPhotoUpload media_url={this.props.media_url} public_photo={this.props.public_photo} />
            <div className="inline long-form-description">
              <p className="group-label" style={{ color: '#777', margin: '10px 0' }}>
                Public long description
              </p>
              <div
                id="public_long_form_description"
                style={{ maxHeight: 200, width: 400, overflow: 'auto' }}
                dangerouslySetInnerHTML={publicLongDescriptionMarkup()}
              />
            </div>
            <div className="inline">
              <modules.formElements.create
                postLabelStyle={{ whiteSpace: 'normal', width: '370px' }}
                label="Allow this rule to be booked on channels that do not support any public description fields"
                name="ignore_descriptions_for_3p_bookers"
                id="ignore_descriptions_for_3p_bookers"
                value="true"
                key={'ignore_descriptions_for_3p_bookers'}
                type="checkbox"
                checked={this.props.ignore_descriptions_for_3p_bookers}
              />
            </div>
            <div>
              <span
                style={{
                  display: 'inline-block',
                  fontWeight: '600',
                  width: '100%',
                  margin: '35px 0 10px',
                  color: 'rgba(83, 83, 83, 1.00)',
                }}
              >
                Automatically Include Upgrades in Reservation?
              </span>
              <div
                style={{
                  margin: '0 0 20px',
                }}
              >
                <ToggleButton value={this.state.addAutomaticUpsells} onOnClick={this.handleOnClick} onOffClick={this.handleOffClick} />
              </div>
              {this.state.addAutomaticUpsells && (
                <AutomaticUpsells
                  selectedAutomaticUpsells={this.props.selectedAutomaticUpsells}
                  upsellsToInventory={this.props.upsellsToInventory}
                  upsellCategories={this.props.upsellCategories}
                  media_url={this.props.media_url}
                />
              )}
            </div>
          </div>
        )
      },
    }),

    convertToJSDate: function (date, forceMonthDay) {
      var newDate = new Date(date.replace(/-/g, '/'))
      // FIXME: The implementation of date varies slightly by browsers. This will cause a problem. Then again, so will the raw ES6 here
      var dateDisplay =
        forceMonthDay || Pmp.Utils.isMonthDayDateFormat()
          ? newDate.getMonth() + 1 + '/' + newDate.getDate()
          : newDate.getDate() + '/' + (newDate.getMonth() + 1)
      return dateDisplay + '/' + newDate.getFullYear()
    },

    inventorySection: function (
      ruleObj,
      calendar_image,
      media_url,
      startTime,
      endTime,
      accessTimeType,
      specificTimes,
      seatingareadata,
      addOverlayCallback,
      selectedDay,
      is_recurring,
      type,
      host_page,
      is_thefork_integration_enabled,
      is_google_booking_enabled
    ) {
      var daySelection = (
          <helpers.accessruleDaySelection
            hidden={_.contains([EDIT_TYPE_OVERRIDE], type)}
            selectedDays={ruleObj.selectedDays}
            startDay={ruleObj.startDay}
            addOverlayCallback={addOverlayCallback}
          />
        ),
        dateSelection = _.contains([EDIT_TYPE_OVERRIDE], type) ? undefined : (
          <helpers.accessRuleDateIntervals
            startDay={EDIT_TYPE_FOLLOWING === type ? selectedDay : ruleObj.startDay}
            endDay={ruleObj.endDay}
            isEditMode={helpers.mode === 'edit'}
            isIndefinite={ruleObj.is_indefinite}
            calendar_image={calendar_image}
          />
        ),
        startTime = Pmp.Utils.timeWithLocale(startTime),
        endTime = Pmp.Utils.timeWithLocale(endTime),
        specificTimesConverted = Pmp.Manager.Global._is_military_time ? _.map(specificTimes, Pmp.Utils.toMilitaryTime) : specificTimes,
        restrictToShifts = ruleObj.restrict_to_shifts
      return (
        <div className="section">
          <helpers.accessRuleName
            value={!ruleObj.is_override && type === EDIT_TYPE_OVERRIDE ? ruleObj.name + ' ' + selectedDay : ruleObj.name}
          />
          {dateSelection}
          {daySelection}
          <helpers.accessShiftCategorySelection
            startTime={startTime}
            specificTimes={specificTimesConverted}
            access_time_type={accessTimeType}
            endTime={endTime}
            restrictToShifts={restrictToShifts}
            selected_shift_categories={ruleObj.shift_categories}
            media_url={media_url}
            host_page={host_page}
          />
          <helpers.bookingRestrictions
            media_url={media_url}
            inventory_count={ruleObj.inventory_count}
            inventory_type={ruleObj.inventory_type}
            max={ruleObj.is_shift_default_party_sizes ? null : ruleObj.party_size_max ? ruleObj.party_size_max : null}
            min={ruleObj.is_shift_default_party_sizes ? null : ruleObj.party_size_min ? ruleObj.party_size_min : null}
            selected_seating_area_ids={ruleObj.seating_area_ids}
            selected_table_ids={ruleObj.table_ids}
            cutoff_num={ruleObj.cutoff_num}
            cutoff_type={ruleObj.cutoff_type}
            cutoff_hour={ruleObj.cutoff_hour_display}
            is_held={ruleObj.is_held}
            venue_seating_areas={seatingareadata.venue_seating_areas}
            all_table_inventory_items={seatingareadata.all_table_inventory_items}
            default_pacing={ruleObj.default_pacing}
            custom_pacing={ruleObj.custom_pacing}
            is_pacing_held={ruleObj.is_pacing_held}
            accessTimeType={accessTimeType}
            startTime={startTime}
            endTime={endTime}
            specificTimes={specificTimesConverted}
            duration_min={ruleObj.duration_min}
            duration_max={ruleObj.duration_max}
            is_thefork_integration_enabled={is_thefork_integration_enabled}
            thefork_seating_area={ruleObj.thefork_seating_area}
            is_google_booking_enabled={is_google_booking_enabled}
            google_reserve_seating_area={ruleObj.google_reserve_seating_area}
            guarantee_bookings={ruleObj.guarantee_bookings}
            exclude_from_shift_pacing={ruleObj.exclude_from_shift_pacing}
          />
        </div>
      )
    },

    displayHideWaitlistWarning: function () {
      var hasWaitlistAudience = false
      for (var i = 1; i < 4; i++) {
        hasWaitlistAudience = !!_.filter($('input[name=tier_type_' + i + ']'), function (channelInput) {
          if (channelInput.value === 'WAITLIST') {
            return channelInput.value
          }
        }).length
        if (hasWaitlistAudience) {
          break
        }
      }
      if (hasWaitlistAudience) {
        $('#waitlist-warning-banner').css('display', 'flex')
      } else {
        $('#waitlist-warning-banner').hide()
      }
    },

    audienceSection: function (media_url, accessObj, audience_hierarchy) {
      var tier1Data = accessObj.audience_tiers[0] || {},
        tier2Data = accessObj.audience_tiers[1] || {},
        tier3Data = accessObj.audience_tiers[2] || {}
      var tierData = [tier1Data, tier2Data, tier3Data]
      var displayWaitlistWarningBanner = false
      tierData.forEach(function (data) {
        if (Object.keys(data).length && data.channels.includes('WAITLIST')) {
          displayWaitlistWarningBanner = true
        }
      })
      const hasExclusiveAccessPerk = !!accessObj.exclusive_access_perk_id

      return (
        <div className="section">
          <div
            id="waitlist-warning-banner"
            style={{
              display: displayWaitlistWarningBanner ? 'flex' : 'none',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(243,55,108,0.07)',
              margin: '20px 0px',
              padding: '20px',
            }}
          >
            <i className="svr-icon- svr-icon--info" style={{ fontSize: '20px' }} />
            <div
              style={{
                display: 'inline-block',
                padding: '0 0 0 10px',
              }}
            >
              The Waitlist Audience currently supports a limited set of access rule functionality.&nbsp;
              <a href="https://help.sevenrooms.com/hc/en-us/articles/360034725952" style={{ display: 'inline-block' }} target="_blank">
                Click here to learn more
              </a>
            </div>
          </div>
          <helpers.accessTier
            media_url={media_url}
            name="tier_type_1"
            index="1"
            num={tier1Data.start_num}
            type={tier1Data.start_type}
            hour={tier1Data.start_hour_display}
            selectedChannels={(tier1Data.channels || []).concat(tier1Data.concierges || [], tier1Data.third_parties || [])}
            audience_hierarchy={audience_hierarchy}
            disableEdit={hasExclusiveAccessPerk || tier1Data.is_early_access}
          />
          <helpers.accessTier
            media_url={media_url}
            name="tier_type_2"
            index="2"
            num={tier2Data.start_num}
            type={tier2Data.start_type}
            hour={tier2Data.start_hour_display}
            selectedChannels={(tier2Data.channels || []).concat(tier2Data.concierges || [], tier2Data.third_parties || [])}
            audience_hierarchy={audience_hierarchy}
            disableEdit={hasExclusiveAccessPerk || tier2Data.is_early_access}
          />
          <helpers.accessTier
            media_url={media_url}
            name="tier_type_3"
            index="3"
            num={tier3Data.start_num}
            type={tier3Data.start_type}
            hour={tier3Data.start_hour_display}
            selectedChannels={(tier3Data.channels || []).concat(tier3Data.concierges || [], tier3Data.third_parties || [])}
            audience_hierarchy={audience_hierarchy}
            disableEdit={hasExclusiveAccessPerk || tier3Data.is_early_access}
          />
        </div>
      )
    },

    applyCalendarBehavior: function (divElement, selectedDate, callback, minDate) {
      var dateFmt = Pmp.Utils.dateFormat(Pmp.Manager.Global._locale)
      var jsDate = $.datepicker.parseDate(dateFmt, selectedDate)
      $(divElement).datepicker({
        dateFormat: dateFmt,
        altField: divElement + '-submit',
        altFormat: 'mm/dd/yy',
        minDate: 0,
        closeText: 'Cancel',
        firstDay: 0,
        parentObj: this,
        showButtonPanel: true,

        beforeShow: function () {
          $('#ui-datepicker-div').addClass('customize')
        },
        onSelect: function (dateText, selObj) {
          if (callback) {
            callback(dateText)
          }
        },
        prependZero: function (num) {
          return ('0' + num).slice(-2)
        },
      })
      $(divElement).datepicker('setDate', jsDate, true)
    },

    onMountCallBack: function () {
      'use strict'
    },
  }

  var isValidParams = function (id) {
    var validator = new sr.Validator($('#' + id).parent(), 'USD')
    validator.activeFieldAttr = 'data-sr-validate'
    validator.errorDisplayAttr = 'data-sr-validate-error'
    var isValid = validator.validate()
    if (!isValid) {
      // Find the first invalid element
      var firstInvalidElement = _.first(
        _.filter($('.validator'), function (x) {
          return $(x).css('display') === 'block'
        })
      )
      // Activate that tab
      if (firstInvalidElement) {
        $(firstInvalidElement).closest('.content').prev('.section-header-label').trigger('click')
      }
    }
    var party_size_min = Number($('[name=party_size_min]').val()),
      party_size_max = Number($('[name=party_size_max]').val())

    if (party_size_max < party_size_min) {
      UserNotificationInterop.error('Party size maximum must exceed party size mininum')
      isValid = false
    }
    var duration_min = Number($('[name=duration_min]').val()),
      duration_max = Number($('[name=duration_max]').val())

    if (duration_max < duration_min) {
      UserNotificationInterop.error('Duration maximum must exceed duration mininum')
      isValid = false
    }
    return isValid
  }

  var build_tier_channels = function (serializedArray, audience_hierarchy, tier_num) {
    var tier_channels = _.pluck(_.where(serializedArray, { name: 'tier_type_' + tier_num }), 'value'),
      group_channels = _.pluck(_.where(serializedArray, { name: 'tier_type_' + tier_num + '_selected_group' }), 'value'),
      filter_children_of_selected_groups = function (isParentSelected, item) {
        if (item.children) {
          var isSelected = isParentSelected || _.contains(group_channels, item.value)
          _.map(item.children, _.partial(filter_children_of_selected_groups, isSelected))
          if (!isParentSelected && isSelected) {
            tier_channels.push(item.value)
          }
        } else if (isParentSelected) {
          var index = tier_channels.indexOf(item.value)
          if (index > -1) {
            tier_channels.splice(index, 1)
          }
        }
      }
    _.map(audience_hierarchy, _.partial(filter_children_of_selected_groups, false))
    return tier_channels.join(',')
  }

  var booking_policy_id = null
  var policy_type = null
  var cancellation_policy_id = null
  var cancellation_policy_type = null

  var changeBookingPolicyID = function (value) {
    booking_policy_id = value
  }
  var changePolicyType = function (value) {
    policy_type = value
  }
  var changeCancellationPolicyType = function (value) {
    cancellation_policy_type = value
  }
  var changeCancellationPolicyID = function (value) {
    cancellation_policy_id = value
  }

  var get_data_for_api = function (id, venue_name, audience_hierarchy) {
    var $id_selector = $('#' + id)
    var serializedArray = $id_selector.serializeArray()
    var cutoff_hour = (_.findWhere(serializedArray, { name: 'cutoff_hour' }) || {})['value']
    var start_hour_1 = (_.findWhere(serializedArray, { name: 'start_hour_1' }) || {})['value']
    var start_hour_2 = (_.findWhere(serializedArray, { name: 'start_hour_2' }) || {})['value']
    var start_hour_3 = (_.findWhere(serializedArray, { name: 'start_hour_3' }) || {})['value']
    var custom_pacing_times = _.collect(_.where(serializedArray, { name: 'custom_pacing_time' }), 'value')
    var custom_pacing_values = _.collect(_.where(serializedArray, { name: 'custom_pacing_value' }), 'value')
    var custom_pacing_object = _.object(custom_pacing_times, custom_pacing_values)

    if (cutoff_hour === '0') {
      serializedArray = _.reject(serializedArray, { name: 'cutoff_hour' })
    }
    if (start_hour_1 === '0') {
      serializedArray = _.reject(serializedArray, { name: 'start_hour_1' })
    }
    if (start_hour_2 === '0') {
      serializedArray = _.reject(serializedArray, { name: 'start_hour_2' })
    }
    if (start_hour_3 === '0') {
      serializedArray = _.reject(serializedArray, { name: 'start_hour_3' })
    }
    var table_ids = _.map(_.where(serializedArray, { name: 'table_ids' }), function (t) {
      return t.value
    }).join(',')
    var seating_area_ids = _.map(_.where(serializedArray, { name: 'seating_area_ids' }), function (t) {
      return t.value
    }).join(',')
    serializedArray = _.reject(serializedArray, { name: 'table_ids' })
    serializedArray = _.reject(serializedArray, { name: 'seating_area_ids' })
    var specificTimes = _.map(_.where(serializedArray, { name: 'specific_times' }), function (t) {
      return t.value
    }).join(',')
    serializedArray = _.reject(serializedArray, { name: 'specific_times' })
    var days = _.map(_.where(serializedArray, { name: 'days' }), function (t) {
      return t.value
    })
    var tier_channels_1 = build_tier_channels(serializedArray, audience_hierarchy, 1)
    var tier_channels_2 = build_tier_channels(serializedArray, audience_hierarchy, 2)
    var tier_channels_3 = build_tier_channels(serializedArray, audience_hierarchy, 3)
    var table_ids_params = table_ids !== '' ? 'table_ids=' + table_ids : ''
    var specific_times_params = specificTimes !== '' ? 'specific_times=' + specificTimes : ''
    var seating_area_ids_params = seating_area_ids !== '' ? 'seating_area_ids=' + seating_area_ids : ''
    var daysString = _.isEmpty(days) ? '' : 'days=' + days.join(',')
    // Use the hidden `start_date` (locale-independent) field to get the value
    var disabledStartDate = $id_selector.find('input[name=start_date_cal]').is(':disabled')
      ? $id_selector.find('input[name=start_date]').val()
      : undefined

    var public_long_form_description = document.querySelector('.ql-editor').innerHTML

    var is_using_shift_upsells = _.findWhere(serializedArray, { name: 'is_using_shift_upsells' }).value
    is_using_shift_upsells = is_using_shift_upsells === 'true'

    var add_upsells_flag = _.findWhere(serializedArray, { name: 'add_upsells' }).value
    add_upsells_flag = add_upsells_flag === 'true'

    const excludeFromShiftPacingElement = document.querySelector('input[name=exclude_from_shift_pacing]')
    const excludeFromShiftPacing = excludeFromShiftPacingElement ? excludeFromShiftPacingElement.checked : false

    var selected_upsells =
      !is_using_shift_upsells && add_upsells_flag ? _.findWhere(serializedArray, { name: 'selected_upsells' }).value : ''
    return (
      $.param(_.reject(serializedArray, item => item.name === 'days' || item.name === 'selected_upsells' || item.name === 'add_upsells')) +
      '&' +
      table_ids_params +
      '&' +
      seating_area_ids_params +
      '&' +
      daysString +
      '&' +
      specific_times_params +
      '&' +
      $.param(
        _.extend(
          {},
          {
            venue: venue_name,
            custom_pacing: JSON.stringify(custom_pacing_object),
            selected_upsells: selected_upsells,
            booking_policy_id: booking_policy_id,
            policy_type: policy_type,
            cancellation_policy_id: cancellation_policy_id,
            cancellation_policy_type: cancellation_policy_type,
            exclude_from_shift_pacing: excludeFromShiftPacing,
          },
          disabledStartDate ? { start_date: disabledStartDate } : {},
          { public_long_form_description: public_long_form_description },
          tier_channels_1 ? { audiences_1: tier_channels_1 } : {},
          tier_channels_2 ? { audiences_2: tier_channels_2 } : {},
          tier_channels_3 ? { audiences_3: tier_channels_3 } : {}
        )
      )
    )
  }

  var editRule = function (
    id,
    venue_name,
    rule,
    type,
    selectedDay,
    tag_groups,
    viewCallBackHandler,
    host_page,
    audience_hierarchy,
    venue_default_booking_policy_id,
    venue_default_cancellation_policy_id
  ) {
    var $button_bottom = $('.button.bottom')
    if (
      _.size(
        _.filter($button_bottom.find('a'), function (x) {
          return $(x).hasClass('disabled')
        })
      )
    ) {
      return
    }
    var startDate = type === EDIT_TYPE_ALL ? formatDate(new Date()) : selectedDay,
      recurring = type === EDIT_TYPE_FOLLOWING || type === EDIT_TYPE_ALL,
      entire_series = type === EDIT_TYPE_ALL

    if (!isValidParams(id)) {
      return
    }
    $button_bottom.find('a').addClass('disabled')

    $.ajax({
      method: 'PUT',
      url: '/api-yoa/booking_access/' + rule.id,
      data:
        get_data_for_api(id, venue_name, audience_hierarchy) +
        '&' +
        'from_date=' +
        selectedDay +
        '&' +
        'start_date=' +
        startDate +
        '&' +
        'is_recurring=' +
        recurring +
        '&' +
        'entire_series=' +
        entire_series,
      success: function (response) {
        if (response.status !== 200) {
          UserNotificationInterop.error(response.msg)
        } else {
          if ($('#' + id).find('.close a')[0]) {
            viewCallBackHandler(response.data, tag_groups, host_page, venue_default_booking_policy_id, venue_default_cancellation_policy_id)
            if (host_page === 'DAYVIEW') {
              modules.accessrulesDayViewManager.refresh(selectedDay)
            } else {
              modules.accessrulesWeekViewManager.refresh(selectedDay)
            }
          }
        }
      },
      error: function (jqXHR, textStatus, error) {
        var response = JSON.parse(jqXHR.responseText)
        UserNotificationInterop.error(response.msg)
      },

      complete: function () {
        $('.button.bottom').find('a').removeClass('disabled')
        CustomerSuccessTracker.trackAvailability('Edit Access Rules')
        parseAccessRules(venue_name)
      },
    })
  }

  var addRule = function (
    id,
    venue_name,
    selectedDay,
    tag_groups,
    viewCallBackHandler,
    host_page,
    audience_hierarchy,
    event,
    venue_default_booking_policy_id,
    venue_default_cancellation_policy_id
  ) {
    if ($(event.currentTarget).hasClass('disabled')) {
      return
    }
    if (!isValidParams(id)) {
      return
    }
    $(event.currentTarget).addClass('disabled')

    $.ajax({
      method: 'POST',
      url: '/api-yoa/booking_access',
      data: get_data_for_api(id, venue_name, audience_hierarchy) + '&from_date=' + selectedDay,
      success: function (response) {
        if (response.status !== 200) {
          UserNotificationInterop.error(response.msg)
        } else {
          if ($('#' + id).find('.close a')[0]) {
            var start_date = $('#' + id)
              .find('[name=start_date]')
              .val()
            viewCallBackHandler(
              response.data.access,
              tag_groups,
              host_page,
              venue_default_booking_policy_id,
              venue_default_cancellation_policy_id
            )
            if (host_page === 'DAYVIEW') {
              modules.accessrulesDayViewManager.refresh(start_date)
            } else {
              modules.accessrulesWeekViewManager.refresh(start_date)
            }
          }
        }
      },
      error: function (jqXHR, textStatus, error) {
        var response = JSON.parse(jqXHR.responseText)
        UserNotificationInterop.error(response.msg)
      },

      complete: function () {
        $('.button.bottom').find('a').removeClass('disabled')
        parseAccessRules(venue_name)
      },
    })
  }

  var editSlide = function (
    id,
    audience_hierarchy,
    venue_name,
    media_url,
    selectedDay,
    seatingareadata,
    rule,
    addOverlayHandler,
    onCloseClickHandler,
    viewCallBackHandler,
    is_recurring,
    payment_setup,
    tax_rate,
    tax_groups,
    can_save_card,
    currency_symbol,
    venue_policy,
    venue_cancellation_policy,
    upsells,
    upsellsToInventory,
    experiences,
    venueSettings,
    type,
    headerLabel,
    tag_groups,
    host_page,
    venue_default_booking_policy_id,
    venue_default_cancellation_policy_id
  ) {
    var start_date = rule.is_override ? rule.date : rule.start_date
    var end_date = rule.is_override ? rule.date : rule.end_date
    var upsell_categories = upsells.categories
    var calendar_image = media_url + 'images/icons/calendar-negative.png',
      startDay = helpers.convertToJSDate(start_date),
      endDay = end_date ? helpers.convertToJSDate(end_date) : undefined,
      ruleObj = _.extend(
        {},
        {
          selectedDays: modules.weekViewManager.getSelectedDays(rule.day_of_week),
          startDay: startDay,
          startDate: start_date,
          endDay: endDay,
        },
        rule
      )

    var dateFmt = Pmp.Utils.dateFormat(Pmp.Manager.Global._locale)
    var selDt = $.datepicker.parseDate('mm/dd/yy', selectedDay)
    var selectedDayIntl = $.datepicker.formatDate(dateFmt, selDt)
    var selected_experience_key = rule.experience_id
    var is_thefork_integration_enabled = window.globalInit.venueSettings.is_thefork_integration_enabled
    var is_google_booking_enabled = window.globalInit.venueSettings.is_google_booking_enabled
    var _policy_type = rule.booking_policy_id || rule.policy_type
    var policy_type =
      (venue_default_booking_policy_id && _policy_type === 'default') || !_policy_type ? venue_default_booking_policy_id : _policy_type
    var _cancellation_policy_type = rule.payment_policy_id || rule.cancellation_policy_type
    var cancellation_policy_type =
      (venue_default_cancellation_policy_id && _cancellation_policy_type === 'default') || !_cancellation_policy_type
        ? venue_default_cancellation_policy_id
        : _cancellation_policy_type
    modules.slideout.render(
      'edit',
      [
        {
          name: 'switch-slideout',
          noExpand: true,
          content: () => switchSlideoutComponent,
        },
        {
          label: '1. Inventory',
          content: _.partial(
            helpers.inventorySection,
            ruleObj,
            calendar_image,
            media_url,
            rule.start_time_display,
            rule.end_time_display,
            rule.access_time_type,
            rule.specific_times_display,
            seatingareadata,
            addOverlayHandler,
            selectedDayIntl,
            is_recurring,
            type,
            host_page,
            is_thefork_integration_enabled,
            is_google_booking_enabled
          ),
        },
        {
          label: '2. Audience',
          content: _.partial(helpers.audienceSection, media_url, ruleObj, audience_hierarchy),
        },
        {
          label: '3. Payment & Policy',
          content: function () {
            return (
              <helpers.paymentAndPolicyTab
                media_url={media_url}
                payment_setup={payment_setup}
                default_policy={venue_policy}
                default_cancellation_policy={venue_cancellation_policy}
                tax_rate={tax_rate}
                currency_symbol={currency_symbol}
                initial_use_shift_payment_and_policy={rule.use_shift_payment_and_policy}
                initial_require_credit_card={rule.require_credit_card}
                initial_party_size_min={rule.cc_party_size_min}
                initial_payment_rule={rule.cc_payment_rule}
                initial_cost={rule.cc_cost}
                initial_charge_type={rule.cc_charge_type}
                initial_apply_tax_rate={rule.cc_apply_tax_rate}
                initial_gratuity={rule.cc_gratuity}
                apply_service_charge={rule.apply_service_charge}
                service_charge_type={rule.service_charge_type}
                service_charge={rule.service_charge}
                apply_gratuity_charge={rule.apply_gratuity_charge}
                gratuity_type={rule.gratuity_type}
                require_gratuity_charge={rule.require_gratuity_charge}
                tax_groups={tax_groups}
                tax_group_id={rule.tax_group_id}
                initial_policy_type={policy_type}
                initial_policy={rule.policy}
                initial_cancellation_policy_type={cancellation_policy_type}
                initial_cancellation_policy={rule.cancellation_policy}
                auto_charge_type={rule.auto_charge_type}
                auto_charge_amount={rule.auto_charge_amount}
                auto_charge_amount_in_cents={rule.auto_charge_amount_in_cents}
                auto_charge_amount_type={rule.auto_charge_amount_type}
                auto_cutoff_num={rule.auto_cutoff_num}
                auto_cutoff_type={rule.auto_cutoff_type}
                auto_cutoff_hour={rule.auto_cutoff_hour}
                auto_cutoff_hour_display={rule.auto_cutoff_hour_display}
                cancel_cutoff_num={rule.cancel_cutoff_num}
                cancel_cutoff_type={rule.cancel_cutoff_type}
                cancel_cutoff_hour={rule.cancel_cutoff_hour}
                cancel_cutoff_hour_display={rule.cancel_cutoff_hour_display}
                can_save_card={can_save_card}
                ignore_cc_for_3p_bookers={rule.ignore_cc_for_3p_bookers}
                default_service_charge={venueSettings.default_service_charge}
                default_gratuity={venueSettings.default_gratuity}
                venue_settings={venueSettings}
                venue_id={window.globalInit.venueId}
              />
            )
          },
        },
        {
          label: '4. Public Description (Optional)',
          content: function () {
            return (
              <helpers.publicDescriptionTab
                media_url={media_url}
                public_time_slot_description={rule.public_time_slot_description}
                public_description_title={rule.public_description_title}
                public_photo={rule.public_photo}
                public_long_form_description={rule.public_long_form_description}
                ignore_descriptions_for_3p_bookers={rule.ignore_descriptions_for_3p_bookers}
                selectedAutomaticUpsells={rule.selected_automatic_upsells}
                upsellsToInventory={upsellsToInventory}
                upsellCategories={upsell_categories}
                experiences={experiences}
                selected_experience_key={selected_experience_key}
                venueSettings={venueSettings}
              />
            )
          },
        },
        {
          label: '5. Upgrades & Reservation Tags',
          content: function () {
            return (
              <PerksAndUpsells
                selectedUpsells={rule.upsell_categories}
                upsellCategories={upsell_categories}
                section="ACCESS"
                isUsingShiftUpsells={rule.is_using_shift_upsells}
                tagGroups={tag_groups}
                selectedTags={rule.reservation_tags}
              />
            )
          },
        },
      ],
      headerLabel,
      <div
        className="button bottom"
        style={{
          color: '#888',
          padding: '1vh',
          borderTop: '1px solid #CACACA !important',
        }}
      >
        <a
          style={{ maxHeight: '38px' }}
          onClick={_.partial(
            editRule,
            id,
            venue_name,
            rule,
            type,
            selectedDay,
            tag_groups,
            viewCallBackHandler,
            host_page,
            audience_hierarchy,
            venue_default_booking_policy_id,
            venue_default_cancellation_policy_id
          )}
        >
          <span>Save</span>
        </a>
      </div>,
      id,
      media_url,
      _.partial(helpers.onMountCallBack, startDay),
      _.partial(helpers.onMountCallBack, startDay),
      onCloseClickHandler,
      1
    )
  }

  var cloneAccessRule = function (
    id,
    audience_hierarchy,
    venue_name,
    media_url,
    seatingareadata,
    rule_name,
    shift_categories,
    selectedDay,
    startTime,
    endTime,
    addOverlayHandler,
    onCloseClickHandler,
    default_policy,
    default_cancellation_policy,
    payment_setup,
    tax_rate,
    tax_groups,
    can_save_card,
    currency_symbol,
    venue_policy,
    venue_cancellation_policy,
    upsells,
    upsellsToInventory,
    experiences,
    venueSettings,
    existing_rule,
    tag_groups,
    venue_default_booking_policy_id,
    venue_default_cancellation_policy_id,
    is_thefork_integration_enabled,
    is_google_booking_enabled
  ) {
    modules.accessslideout.add(
      'flyout-form',
      audience_hierarchy,
      venue_name,
      media_url,
      seatingareadata,
      rule_name,
      shift_categories,
      selectedDay,
      startTime,
      endTime,
      addOverlayHandler,
      onCloseClickHandler,
      default_policy,
      default_cancellation_policy,
      payment_setup,
      tax_rate,
      tax_groups,
      can_save_card,
      currency_symbol,
      venue_policy,
      venue_cancellation_policy,
      upsells,
      upsellsToInventory,
      experiences,
      venueSettings,
      existing_rule,
      tag_groups,
      undefined,
      venue_default_booking_policy_id,
      venue_default_cancellation_policy_id,
      is_thefork_integration_enabled,
      is_google_booking_enabled
    )
  }

  var deleteRule = function (id, ruleId, name, type, selectedDay, host_page, event) {
    var $button_bottom_a = $('.button.bottom').find('a')
    if (
      _.size(
        _.filter($button_bottom_a, function (x) {
          return $(x).hasClass('disabled')
        })
      )
    ) {
      return
    }
    $button_bottom_a.addClass('disabled')

    var deletedDate = type === EDIT_TYPE_ALL ? formatDate(new Date()) : selectedDay,
      recurring = type === EDIT_TYPE_FOLLOWING || type === EDIT_TYPE_ALL,
      entire_series = type === EDIT_TYPE_ALL,
      params = _.extend(
        {},
        {
          date: deletedDate,
          from_date: selectedDay,
          is_recurring: recurring,
          rule_id: ruleId,
          venue: Pmp.Manager.Global._url_key_or_id,
          entire_series: entire_series,
        }
      )
    $.ajax({
      method: 'DELETE',
      url: '/api-yoa/booking_access/' + ruleId + '?' + $.param(params),
      success: function (response) {
        if (response.status !== 200) {
          UserNotificationInterop.error(response.msg)
        } else {
          var $selector_with_id = $('#' + id).find('.close a')
          if ($selector_with_id[0]) {
            $selector_with_id[0].click()

            if (host_page === 'DAYVIEW') {
              modules.accessrulesDayViewManager.refresh(selectedDay)
            } else {
              modules.accessrulesWeekViewManager.refresh(selectedDay)
            }
          }
        }
      },
      error: function (jqXHR, textStatus, error) {
        var response = JSON.parse(jqXHR.responseText)
        UserNotificationInterop.error(response.msg)
      },
      complete: function () {
        $('.button.bottom').find('a').removeClass('disabled')
        parseAccessRules(name)
      },
    })
  }

  var parseAccessRules = function (urlKey) {
    var date = new Date()
    var start_date = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
    date.setDate(date.getDate() + 30)
    var end_date = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()

    $.ajax({
      method: 'GET',
      url: '/api-yoa/booking_access',
      data: {
        venue: urlKey,
        start_date: start_date,
        end_date: end_date,
      },
      success: function (response) {
        var access_rules = response.data.access
        var access_rule_obj = parseCSTrackerAccessRulesFields(access_rules)
        CustomerSuccessTracker.setAccountAttributes(access_rule_obj)
      },
      error: function (jqXHR, textStatus, error) {
        var response = JSON.parse(jqXHR.responseText)
        UserNotificationInterop.error(response.msg)
      },
    })
  }

  var parseCSTrackerAccessRulesFields = function (access_rules) {
    var has_access_rules = false
    var has_description = false
    var has_perks_and_upgrades = false
    var has_time_slot_description = false
    var audience_set = new Set()

    var rules = _.flatten(Object.values(access_rules))
    rules.forEach(function (rule) {
      var description_title = rule.public_description_title
      var time_slot_description = rule.public_time_slot_description
      var long_form_description = rule.public_long_form_description
      var public_photo = rule.public_photo
      var upsell_categories = rule.upsell_categories
      var channel_audiences = rule.audience_tiers[0].channels || []
      var concierge_audiences = rule.audience_tiers[0].concierges || []
      var third_party_audiences = rule.audience_tiers[0].third_parties || []

      if (!has_access_rules) {
        has_access_rules = true
      }

      if (!has_description && (description_title || (long_form_description !== '<p><br></p>' && long_form_description) || public_photo)) {
        has_description = true
      }

      if (time_slot_description) {
        has_time_slot_description = true
      }

      if (!has_perks_and_upgrades && upsell_categories.length > 0) {
        has_perks_and_upgrades = true
      }

      for (var i = 0; i < channel_audiences.length; i++) {
        if (!audience_set.has(channel_audiences[i])) {
          audience_set.add(channel_audiences[i])
        }
      }
      for (var i = 0; i < concierge_audiences.length; i++) {
        if (!audience_set.has(concierge_audiences[i])) {
          audience_set.add(concierge_audiences[i])
        }
      }
      for (var i = 0; i < third_party_audiences.length; i++) {
        if (!audience_set.has(third_party_audiences[i])) {
          audience_set.add(third_party_audiences[i])
        }
      }
    })

    var access_rule_obj = {
      access_rules: has_access_rules,
      description_active: has_description,
      perks_and_upgrades: has_perks_and_upgrades,
      time_slot_description: has_time_slot_description,
      audiences: audience_set.size,
    }
    return access_rule_obj
  }

  var recurringDecisionOverlay = function (text, selectedDay, endDay, name, onOverrideCallBk, onRecurringSomeCallBk, onRecurringAllCallBk) {
    const dateFormat = Pmp.Utils.dateFormat(Pmp.Manager.Global._locale)
    const selectedDate = $.datepicker.parseDate('mm/dd/yy', selectedDay)
    const selectedDayIntl = $.datepicker.formatDate(dateFormat, selectedDate)

    const today = new Date()
    const todayIntl = $.datepicker.formatDate(dateFormat, today)

    const textInPast = text === 'Delete' ? 'deleted' : 'edited'
    const nameTruncated = name.substring(0, 18)
    const headerText = `${text} ${name} Rule`
    const additionalText = endDay ? `ending on ${endDay}` : ''
    const contentText = `This access rule is a part of a series${additionalText ? ' ' + additionalText : ''}. What would you like to do?`
    const untilEndText = endDay ? ` <strong>until end on ${endDay}</strong>` : ``

    const overrideButtonText = text === 'Delete' ? `Delete ${selectedDayIntl} only` : `Override ${selectedDayIntl} only`
    const overrideDesc = `All other days in ${nameTruncated} will remain unchanged`
    const followingButtonText = `${text} from ${selectedDayIntl} onwards`
    const followingDesc = `${nameTruncated} will be ${textInPast} from <strong>selected date</strong> (${selectedDayIntl}) onwards${untilEndText}`
    const followingSubdesc = `*Single-day overrides will remain unchanged`
    const allButtonText = `${text} all days from today onwards`
    const allDesc = `${nameTruncated} will be ${textInPast} from <strong>today</strong> (${todayIntl}) onwards${untilEndText}`
    const allSubdesc = `*Single-day overrides will remain unchanged`

    const closeLink = $('<a/>').css({ cursor: 'pointer', float: 'right', fontSize: 20, marginTop: '-15px' }).html('&times;')
    const header = $("<div class='header' />")
      .css({
        height: '26px',
        padding: '5px',
        paddingLeft: 15,
        color: '#000',
        marginTop: '10px',
        fontSize: '15px',
        borderBottom: '1px solid lightgrey',
        textTransform: 'uppercase',
      })
      .html(
        $("<div class='text' style='float: left; max-width: 80%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis'/>").sext(
          headerText
        )
      )
      .append(closeLink)
    const bodyHeader = $("<div class='body-text' />")
      .css({
        height: '20px',
        marginTop: '10px',
        marginLeft: '15px',
        textAlign: 'left',
        color: 'black',
        fontSize: '12px',
      })
      .html(contentText)
    const bodyButton1 = $("<div class='body-button recurring-override-button' />").html(
      `<div class="button">${overrideButtonText}</div><div class="desc">${overrideDesc}</div>`
    )
    const bodyButton2 = $("<div class='body-button recurring-following-button' />").html(
      `<div class="button">${followingButtonText}</div><div class="desc2">${followingDesc}<br /><span>${followingSubdesc}</span></div>`
    )
    const bodyButton3 = $("<div class='body-button recurring-all-button' />").html(
      `<div class="button">${allButtonText}</div><div class="desc2">${allDesc}<br /><span>${allSubdesc}</span></div>`
    )
    const nevermind = $("<div class='footer' />").html("<div class='button'>Nevermind</div>")

    let body = $('<div/>').append(header).append(bodyHeader).append(bodyButton1)
    if (selectedDate.setHours(0, 0, 0, 0) !== today.setHours(0, 0, 0, 0)) {
      body = body.append(bodyButton2)
    }
    body = body.append(bodyButton3).append(nevermind)

    // Apply additional css
    $(body).find('.button').css({
      backgroundColor: '#EEE',
      width: '100px',
      padding: '10px 0px',
      cursor: 'pointer',
    })
    $(body).find('.body-button').css({ display: 'flex', flexDirection: 'row', marginRight: '15px', minHeight: '42px' })
    $(body).find('.body-button .button').css({ width: '200px', minWidth: '200px', height: '100%', marginLeft: '15px', color: '#666' })
    $(body).find('.body-button .desc2 span').css({ fontSize: '10px' })
    $(body).find('.footer .button').css({ float: 'right', marginRight: '10px', marginBottom: '10px' })
    $(body).find('.body-button').css({ overflow: 'hidden', paddingTop: '10px' })
    $(body).find('.body-button .desc').css({ textAlign: 'left', marginLeft: '10px', padding: '10px 0px', color: '#000' })
    $(body).find('.body-button .desc2').css({ textAlign: 'left', marginLeft: '10px', color: '#000' })
    $(body).find('.body-button, .footer').css({ fontSize: '12px', color: '#666' })
    $(body).find('.footer').css({ marginTop: '10px' })

    // Event handling
    var closeHandler = function (e) {
      $(e.currentTarget).closest('.blocker').remove()
    }
    $(closeLink).on('click', closeHandler)
    $(nevermind).find('.button').on('click', closeHandler)
    $(bodyButton1)
      .find('.button')
      .on('click', function (e) {
        onOverrideCallBk(e)
        closeHandler(e)
      })
    $(bodyButton2)
      .find('.button')
      .on('click', function (e) {
        onRecurringSomeCallBk(e)
        closeHandler(e)
      })
    $(bodyButton3)
      .find('.button')
      .on('click', function (e) {
        onRecurringAllCallBk(e)
        closeHandler(e)
      })

    return $("<div class='modal' />")
      .css({
        width: '600px',
        height: 'fit-content',
        position: 'fixed',
        top: '50%',
        'font-family': 'Roboto',
        'font-weight': 'normal',
        'font-style': 'normal',
        color: '#999',
        left: '50%',
        'margin-top': '-100px',
        'margin-left': '-150px',
        'background-color': '#FFFFFF',
        'border-radius': '2px',
        'text-align': 'center',
        'z-index': 1001,
      })
      .append(body)
  }

  var deleteDecisionBlock = function (id, name, ruleId, recurring, rule_name, selectedDay, startDay, endDay, host_page) {
    if (
      _.size(
        _.filter($('.button.bottom').find('a'), function (x) {
          return $(x).hasClass('disabled')
        })
      )
    ) {
      return
    }

    if (!recurring) {
      deleteRule(id, ruleId, name, EDIT_TYPE_OVERRIDE, selectedDay, host_page)
    } else if (startDay === endDay) {
      // One day recurring rule, just delete entirely
      deleteRule(id, ruleId, name, EDIT_TYPE_ALL, selectedDay, host_page)
    } else {
      var $blocker = $('.blocker')
      var blockerElem = $blocker.size() ? $blocker : $("<div class='blocker'/>")
      blockerElem
        .css({
          'z-index': 1000,
          position: 'fixed',
          width: '100%',
          height: '100%',
          background: 'rgba(1,1,1,0.5)',
          left: 0,
          top: 0,
        })
        .append(
          recurringDecisionOverlay(
            'Delete',
            selectedDay,
            endDay,
            rule_name,
            _.partial(deleteRule, id, ruleId, name, EDIT_TYPE_OVERRIDE, selectedDay, host_page),
            _.partial(deleteRule, id, ruleId, name, EDIT_TYPE_FOLLOWING, selectedDay, host_page),
            _.partial(deleteRule, id, ruleId, name, EDIT_TYPE_ALL, selectedDay, host_page)
          )
        )
        .appendTo('body')
    }
  }

  var editDecisionBlock = function (
    invokeEditSlideOut,
    recurring,
    selectedDay,
    name,
    startDay,
    endDay,
    tag_groups,
    host_page,
    venue_default_booking_policy_id,
    venue_default_cancellation_policy_id
  ) {
    if (
      _.size(
        _.filter($('.button.bottom').find('a'), function (x) {
          return $(x).hasClass('disabled')
        })
      )
    ) {
      return
    }

    if (!recurring) {
      invokeEditSlideOut(
        EDIT_TYPE_OVERRIDE,
        name,
        tag_groups,
        host_page,
        venue_default_booking_policy_id,
        venue_default_cancellation_policy_id
      )
    } else if (startDay === endDay) {
      // One day recurring rule, just edit entirely
      invokeEditSlideOut(EDIT_TYPE_ALL, name, tag_groups, host_page, venue_default_booking_policy_id, venue_default_cancellation_policy_id)
    } else {
      var dateFmt = Pmp.Utils.dateFormat(Pmp.Manager.Global._locale)
      var selDt = $.datepicker.parseDate('mm/dd/yy', selectedDay)
      var selectedDayIntl = $.datepicker.formatDate(dateFmt, selDt)
      var $blocker = $('.blocker')
      var blockerElem = $blocker.size() ? $blocker : $("<div class='blocker'/>")
      blockerElem
        .css({
          'z-index': 1000,
          position: 'fixed',
          width: '100%',
          height: '100%',
          background: 'rgba(1,1,1,0.5)',
          left: 0,
          top: 0,
        })
        .append(
          recurringDecisionOverlay(
            'Edit',
            selectedDay,
            endDay,
            name,
            _.partial(
              invokeEditSlideOut,
              EDIT_TYPE_OVERRIDE,
              'OVERRIDE (' + name + ') - ' + selectedDayIntl,
              tag_groups,
              host_page,
              venue_default_booking_policy_id,
              venue_default_cancellation_policy_id
            ),
            _.partial(
              invokeEditSlideOut,
              EDIT_TYPE_FOLLOWING,
              'EDIT (' + name + ') - FROM ' + selectedDayIntl + ' onwards',
              tag_groups,
              host_page,
              venue_default_booking_policy_id,
              venue_default_cancellation_policy_id
            ),
            _.partial(
              invokeEditSlideOut,
              EDIT_TYPE_ALL,
              'EDIT (' + name + ') - ' + ' ALL DAYS',
              tag_groups,
              host_page,
              venue_default_booking_policy_id,
              venue_default_cancellation_policy_id
            )
          )
        )
        .appendTo('body')
    }
  }

  var viewSlide = function (
    id,
    audience_hierarchy,
    venue_name,
    media_url,
    shift_categories,
    seatingareadata,
    selectedDay,
    addOverlayHandler,
    onCloseClickHandler,
    payment_setup,
    tax_rate,
    tax_groups,
    can_save_card,
    currency_symbol,
    venue_policy,
    venue_cancellation_policy,
    upsells,
    upsellsToInventory,
    experiences,
    venueSettings,
    rule,
    tag_groups,
    host_page,
    venue_default_booking_policy_id,
    venue_default_cancellation_policy_id,
    perksInfo = {}
  ) {
    // Backwards compatibility, remove upon running migration
    if (rule.shift_category && !rule.shift_categories.includes(rule.shift_category)) {
      rule.shift_categories = [rule.shift_category]
    }
    var start_date = rule.is_override ? rule.date : rule.start_date
    var end_date = rule.is_override ? rule.date : rule.end_date
    var upsell_categories = upsells.categories
    var startDay = helpers.convertToJSDate(start_date),
      endDay = end_date ? helpers.convertToJSDate(end_date) : undefined,
      selected_tables = _.filter(seatingareadata.all_table_inventory_items, function (t) {
        return _.contains(rule.table_ids, t.id)
      }),
      selected_seating_areas = _.filter(seatingareadata.venue_seating_areas, function (t) {
        return _.contains(rule.seating_area_ids, t.id)
      }),
      table_names = _.collect(selected_tables, 'item_code').join(', '),
      is_recurring = !rule.is_override || startDay !== endDay,
      seating_area_names = _.collect(selected_seating_areas, 'code').join(', '),
      isSelectedDateInPast = new Date(selectedDay) < Pmp.Manager.Global._venue_today_date,
      hasAutomaticUpsells = !_.isEmpty(rule.selected_automatic_upsells),
      onEditMouseOver = function (e) {
        $(e.currentTarget).css({ 'background-color': '#e7e7e7' })
      },
      onEditMouseOut = function (e) {
        $(e.currentTarget).css({ 'background-color': '#FFFFFF' })
      },
      onOptionsClick = function (e) {
        $('#more-actions-options').css({ display: 'block' })
      },
      selected_experience =
        rule.experience_id && experiences && experiences[rule.experience_id] ? experiences[rule.experience_id].name : null
    const invokeEditMode = _.partial(
      editSlide,
      id,
      audience_hierarchy,
      venue_name,
      media_url,
      selectedDay,
      seatingareadata,
      rule,
      addOverlayHandler,
      onCloseClickHandler,
      _.partial(
        viewSlide,
        id,
        audience_hierarchy,
        venue_name,
        media_url,
        rule.shift_categories,
        seatingareadata,
        selectedDay,
        addOverlayHandler,
        onCloseClickHandler,
        payment_setup,
        tax_rate,
        tax_groups,
        can_save_card,
        currency_symbol,
        venue_policy,
        venue_cancellation_policy,
        upsells,
        upsellsToInventory,
        experiences,
        venueSettings
      ),
      is_recurring,
      payment_setup,
      tax_rate,
      tax_groups,
      can_save_card,
      currency_symbol,
      venue_policy,
      venue_cancellation_policy,
      upsells,
      upsellsToInventory,
      experiences,
      venueSettings
    )
    const cloneAccessRuleMethod = _.partial(
      cloneAccessRule,
      id,
      audience_hierarchy,
      venue_name,
      media_url,
      seatingareadata,
      ['Copy of', rule.name].join(' '),
      rule.shift_categories,
      selectedDay,
      rule.start_time_display,
      rule.end_time_display,
      addOverlayHandler,
      onCloseClickHandler,
      venue_policy,
      venue_cancellation_policy,
      payment_setup,
      tax_rate,
      tax_groups,
      can_save_card,
      currency_symbol,
      venue_policy,
      venue_cancellation_policy,
      upsells,
      upsellsToInventory,
      experiences,
      venueSettings,
      rule,
      tag_groups,
      venue_default_booking_policy_id,
      venue_default_cancellation_policy_id,
      venueSettings.is_thefork_integration_enabled,
      venueSettings.is_google_booking_enabled
    )
    var includedUpsells = []
    var upsellIdToName = {}
    upsells.inventory.forEach(function (upsell) {
      upsellIdToName[upsell.id] = upsell.name
    })
    rule.selected_automatic_upsells.forEach(function (selectedUpsell) {
      if (selectedUpsell.id in upsellIdToName) {
        includedUpsells.push(upsellIdToName[selectedUpsell.id])
      }
    })
    var includedUpsellDisplay = includedUpsells.join(', ')
    var onCloseActivityLogModal = function () {
      window.SvrManager.MgrActivityLog.unmountActivityLogModal()
    }
    var onViewActivityLog = function () {
      window.SvrManager.MgrActivityLog.renderActivityLogModal({
        entityObjectId: rule.id,
        isActive: true,
        setIsActive: () => {},
        onClose: onCloseActivityLogModal,
        title: rule.name,
      })
    }
    var bottom = isSelectedDateInPast ? (
      !perksInfo.exclusivePerkId && (
        <div>
          <p
            className="button bottom"
            style={{
              color: '#888',
              width: '100%',
              padding: 0,
            }}
          >
            <a
              onClick={onOptionsClick}
              onMouseOver={onEditMouseOver}
              onMouseOut={onEditMouseOut}
              style={{
                float: 'right',
                width: '15%',
                backgroundColor: '#FFF',
                color: '#000',
                padding: 0,
                borderRadius: 0,
                borderLeft: '1px solid #eee',
              }}
            >
              <span>...</span>
            </a>
            <ul
              id="more-actions-options"
              style={{
                position: 'absolute',
                right: '0px',
                bottom: '35px',
                display: 'none',
                zIndex: '1',
                backgroundColor: '#FFF',
                cursor: 'pointer',
                border: '1px solid #ccc',
              }}
            >
              <li
                onClick={cloneAccessRuleMethod}
                onMouseOver={onEditMouseOver}
                onMouseOut={onEditMouseOut}
                style={{
                  padding: '10px 40px',
                  listStyleType: 'none',
                  cursor: 'pointer',
                }}
              >
                Clone access rule
              </li>
              <li
                onClick={onViewActivityLog}
                onMouseOver={onEditMouseOver}
                onMouseOut={onEditMouseOut}
                style={{
                  padding: '10px 40px',
                  listStyleType: 'none',
                  cursor: 'pointer',
                }}
              >
                View Activity log
              </li>
            </ul>
          </p>
        </div>
      )
    ) : (
      <div>
        <p
          className="button bottom"
          style={{
            color: '#888',
            width: '100%',
            padding: 0,
          }}
        >
          <a
            onMouseOver={onEditMouseOver}
            onMouseOut={onEditMouseOut}
            onClick={_.partial(
              editDecisionBlock,
              invokeEditMode,
              is_recurring,
              selectedDay,
              rule.name,
              startDay,
              endDay,
              tag_groups,
              host_page,
              venue_default_booking_policy_id,
              venue_default_cancellation_policy_id
            )}
            style={{
              float: 'left',
              width: perksInfo.exclusivePerkId ? '49%' : '42%',
              backgroundColor: '#FFF',
              color: '#000',
              padding: 0,
            }}
          >
            <span>Edit</span>
          </a>
          <a
            onMouseOver={onEditMouseOver}
            onMouseOut={onEditMouseOut}
            onClick={_.partial(
              deleteDecisionBlock,
              id,
              venue_name,
              rule.id,
              is_recurring,
              rule.name,
              selectedDay,
              startDay,
              endDay,
              host_page
            )}
            style={{
              float: 'left',
              width: perksInfo.exclusivePerkId ? '49%' : '42%',
              backgroundColor: '#FFF',
              color: '#000',
              padding: 0,
              borderRadius: 0,
              borderLeft: '1px solid #eee',
            }}
          >
            <span>Delete</span>
          </a>
          {!perksInfo.exclusivePerkId && (
            <span>
              <a
                onClick={onOptionsClick}
                onMouseOver={onEditMouseOver}
                onMouseOut={onEditMouseOut}
                style={{
                  float: 'left',
                  width: '15%',
                  backgroundColor: '#FFF',
                  color: '#000',
                  padding: 0,
                  borderRadius: 0,
                  borderLeft: '1px solid #eee',
                }}
              >
                <span>...</span>
              </a>
              <ul
                id="more-actions-options"
                style={{
                  position: 'absolute',
                  right: '0px',
                  bottom: '35px',
                  display: 'none',
                  zIndex: '1',
                  backgroundColor: '#FFF',
                  cursor: 'pointer',
                  border: '1px solid #ccc',
                }}
              >
                <li
                  onClick={cloneAccessRuleMethod}
                  onMouseOver={onEditMouseOver}
                  onMouseOut={onEditMouseOut}
                  style={{
                    padding: '10px 40px',
                    listStyleType: 'none',
                    cursor: 'pointer',
                  }}
                >
                  Clone access rule
                </li>
                <li
                  onClick={onViewActivityLog}
                  onMouseOver={onEditMouseOver}
                  onMouseOut={onEditMouseOut}
                  style={{
                    padding: '10px 40px',
                    listStyleType: 'none',
                    cursor: 'pointer',
                  }}
                >
                  View Activity log
                </li>
              </ul>
            </span>
          )}
        </p>
      </div>
    )

    if (checkUseNewArSlideout()) {
      const onRuleUpdate = () => {
        if (host_page === 'DAYVIEW') {
          modules.accessrulesDayViewManager.refresh(selectedDay)
        } else {
          modules.accessrulesWeekViewManager.refresh(selectedDay)
        }
      }

      window.SvrManager.MgrAccessRulesSlideout.render(
        id,
        {
          id: rule.id,
          startDate: rule.startDate,
          startTimeDisplay: rule.start_time_display,
          endTimeDisplay: rule.end_time_display,
          shiftCategories: shift_categories,
          seatingAreaData: seatingareadata,
          audienceHierarchy: audience_hierarchy,
          currencySymbol: currency_symbol,
          canSaveCard: can_save_card,
          venueSettings,
          experiences,
          upsells,
          tagGroups: tag_groups,
          venueName: venue_name,
          selectedDay: selectedDay,
          defaultPolicies: {
            bookingPolicy: venue_policy,
            cancelPolicy: venue_cancellation_policy,
            bookingPolicyId: venue_default_booking_policy_id,
            cancelPolicyId: venue_default_cancellation_policy_id,
          },
          onRuleSave: onRuleUpdate,
          onRuleDelete: onRuleUpdate,
          onClose: onCloseClickHandler,
        },
        switchSlideoutCallback
      )
      return
    }

    modules.slideout.render(
      'view',
      [
        {
          name: 'switch-slideout',
          noExpand: true,
          content: () => switchSlideoutComponent,
        },
        {
          content: () => perkBanner(perksInfo),
        },
        {
          content: function () {
            var tableRestrictionElement = _.size(rule.table_ids) ? (
              <modules.slideout.viewModeElement identifier="Tables restriction" value={table_names} />
            ) : undefined
            var seatingareaRestrictionElement = _.size(rule.seating_area_ids) ? (
              <modules.slideout.viewModeElement identifier="Seating areas restriction" value={seating_area_names} />
            ) : undefined
            var isHeldElement =
              _.size(rule.table_ids) || _.size(rule.seating_area_ids) ? (
                <modules.slideout.viewModeElement
                  identifier="Treated as block"
                  value={rule.is_held ? 'Yes' : 'No'}
                  indent={20}
                  fontStyle="italic"
                />
              ) : undefined
            var maxReservation = rule.inventory_count != null ? (
              <modules.slideout.viewModeElement
                identifier="Max limit per day"
                value={rule.inventory_count + ' ' + rule.inventory_type.toLowerCase()}
              />
            ) : undefined
            var bookingCutoffTime = rule.cutoff_type ? (
              <modules.slideout.viewModeElement
                identifier="Booking cut-off time"
                value={
                  (rule.cutoff_num ? rule.cutoff_num : 'Same') +
                  ' ' +
                  rule.cutoff_type.toLowerCase() +
                  (rule.cutoff_num ? ' in advance ' : ' ') +
                  (rule.cutoff_hour_display ? 'at ' + rule.cutoff_hour_display : 'of reservation time')
                }
              />
            ) : undefined
            var daysSelection =
              rule.start_date !== rule.end_date ? (
                <modules.slideout.viewModeElement
                  identifier="Days"
                  value={modules.weekViewManager.getSelectedDays(rule.day_of_week).join(', ')}
                />
              ) : undefined
            var partySizeRange = (
              <modules.slideout.viewModeElement
                identifier="Party size range"
                value={rule.party_size_min && rule.party_size_max ? rule.party_size_min + ' - ' + rule.party_size_max : 'Follow Shift'}
              />
            )
            var pacingRestrictionElement =
              rule.default_pacing !== null || !_.isEmpty(rule.custom_pacing) ? (
                <modules.slideout.viewModeElement identifier="Max pacing per interval" value={rule.default_pacing} />
              ) : undefined
            var durationRestrictionElement =
              !!rule.duration_min && !!rule.duration_max ? (
                <modules.slideout.viewModeElement
                  identifier="Client must specify duration"
                  value={helpers.minsToDisplayTime(rule.duration_min) + ' - ' + helpers.minsToDisplayTime(rule.duration_max)}
                />
              ) : undefined
            var maxNoOfCoversPerInterval =
              rule.default_pacing !== null || !_.isEmpty(rule.custom_pacing) ? (
                <modules.slideout.viewModeElement identifier="Restrict pacing of covers per interval" value={rule.default_pacing} />
              ) : undefined
            var isPacingHeldElement =
              rule.default_pacing !== null || !_.isEmpty(rule.custom_pacing) ? (
                <modules.slideout.viewModeElement
                  identifier="Treated as block"
                  value={rule.is_pacing_held ? 'Yes' : 'No'}
                  indent={20}
                  fontStyle="italic"
                />
              ) : undefined
            var customPacingKeysSorted = helpers.customPacingKeysSorted(rule.custom_pacing)
            var customPacingElement = _.size(rule.custom_pacing)
              ? _.map(
                  customPacingKeysSorted,
                  function (mins) {
                    return (
                      <modules.slideout.viewModeElement
                        identifier={modules.weekViewManager.getMinutesDisplay(mins)}
                        value={rule.custom_pacing[mins]}
                        indent={20}
                        fontStyle="italic"
                      />
                    )
                  },
                  this
                )
              : undefined
            var startTime = Pmp.Utils.timeWithLocale(rule.start_time_display)
            var endTime = Pmp.Utils.timeWithLocale(rule.end_time_display)
            var specificTimes = Pmp.Manager.Global._is_military_time
              ? _.map(rule.specific_times_display, Pmp.Utils.toMilitaryTime)
              : rule.specific_times_display
            var shiftTimes =
              rule.access_time_type === 'SPECIFIC'
                ? specificTimes.join(', ')
                : rule.access_time_type === 'TIME_RANGE'
                ? startTime + ' - ' + endTime
                : modules.accessrulesShared.build_value_by_shift_times_display(rule.start_end_times_by_shift_display, shift_categories)

            return (
              <div className="section">
                <modules.slideout.viewModeHeader label="Inventory" />
                <modules.slideout.viewModeElement identifier="Rule Name" value={rule.name} />
                <modules.slideout.viewModeElement
                  identifier="Shift category"
                  value={modules.accessrulesShared.build_shift_categories_display(
                    rule.access_time_type,
                    rule.restrict_to_shifts,
                    rule.shift_categories
                  )}
                />
                {daysSelection}
                <modules.slideout.viewModeElement identifier="Date range" value={startDay + ' - ' + (endDay ? endDay : 'Indefinite')} />
                <modules.slideout.viewModeElement identifier="Time" value={shiftTimes} />
                {partySizeRange}
                {maxReservation}
                {bookingCutoffTime}
                {tableRestrictionElement}
                {seatingareaRestrictionElement}
                {maxNoOfCoversPerInterval}
                {isHeldElement}
                {pacingRestrictionElement}
                {isPacingHeldElement}
                {customPacingElement}
                {durationRestrictionElement}
              </div>
            )
          },
        },
        {
          content: function () {
            var channels = _.map(
              rule.audience_tiers,
              function (tier, index) {
                var tier_type =
                    tier.start_num > 1
                      ? tier.start_type
                      : {
                          MONTHS: 'month',
                          WEEKS: 'week',
                          DAYS: 'day',
                          HOURS: 'hour',
                        }[tier.start_type],
                  tierStart =
                    (tier.start_num ? tier.start_num : 'Same') +
                    ' ' +
                    tier_type.toLowerCase() +
                    (tier.start_num ? ' in advance ' : ' ') +
                    (tier.start_hour_display ? 'at ' + tier.start_hour_display : 'of reservation time'),
                  audience_name_lookup = modules.accessrulesShared.build_audience_name_lookup(audience_hierarchy),
                  all_selected_audiences = (tier.concierges || []).concat(tier.third_parties || []).concat(tier.channels || []),
                  labels = _.compact(
                    _.map(all_selected_audiences, function (audience) {
                      return audience_name_lookup[audience]
                    })
                  )
                return (
                  <span key={index}>
                    <modules.slideout.viewModeElement identifier={'Tier ' + (index + 1)} value={labels.join(', ')} />
                    <modules.slideout.viewModeElement identifier={'Tier ' + (index + 1) + ' start'} value={tierStart} />
                  </span>
                )
              },
              this
            )

            return rule.audience_tiers.length ? (
              <div className="section" style={{ paddingTop: 30 }}>
                <modules.slideout.viewModeHeader label="Audience" />
                {channels}
              </div>
            ) : undefined
          },
        },
        {
          content: function () {
            if (rule.use_shift_payment_and_policy) {
              return (
                <div className="section" style={{ paddingTop: 30 }}>
                  <modules.slideout.viewModeHeader label="Payment & Policy" />
                  <modules.slideout.viewModeElement identifier="Follow payment and policy configured for this shift" value="Yes" />
                  <modules.slideout.viewModeElement
                    identifier="Allow this rule to be booked on channels that do not support credit card holds"
                    value={rule.ignore_cc_for_3p_bookers ? 'Yes' : 'No'}
                  />
                </div>
              )
            }
            var venue_settings = window.globalInit.venueSettings
            var policy_type = rule.booking_policy_id || rule.policy_type
            return (
              <PaymentAndPolicyView
                currency_symbol={currency_symbol}
                require_credit_card={rule.require_credit_card}
                party_size_min={rule.cc_party_size_min}
                payment_rule={rule.cc_payment_rule}
                cost={rule.cc_cost}
                charge_type={rule.cc_charge_type}
                apply_tax_rate={rule.cc_apply_tax_rate}
                tax_groups={tax_groups}
                tax_group_id={rule.tax_group_id}
                gratuity={rule.cc_gratuity}
                apply_service_charge={rule.apply_service_charge}
                service_charge_type={rule.service_charge_type}
                service_charge={rule.service_charge}
                apply_gratuity_charge={rule.apply_gratuity_charge}
                gratuity_type={rule.gratuity_type}
                require_gratuity_charge={rule.require_gratuity_charge}
                policy_type={policy_type}
                cancellation_policy_type={rule.cancellation_policy_type}
                auto_charge_type={rule.auto_charge_type}
                auto_charge_amount={rule.auto_charge_amount}
                auto_charge_amount_in_cents={rule.auto_charge_amount_in_cents}
                auto_charge_amount_type={rule.auto_charge_amount_type}
                auto_cutoff_num={rule.auto_cutoff_num}
                auto_cutoff_type={rule.auto_cutoff_type}
                auto_cutoff_hour={rule.auto_cutoff_hour}
                auto_cutoff_hour_display={rule.auto_cutoff_hour_display}
                cancel_cutoff_num={rule.cancel_cutoff_num}
                cancel_cutoff_type={rule.cancel_cutoff_type}
                cancel_cutoff_hour={rule.cancel_cutoff_hour}
                cancel_cutoff_hour_display={rule.cancel_cutoff_hour_display}
                can_save_card={can_save_card}
                ignore_descriptions_for_3p_bookers={rule.ignore_descriptions_for_3p_bookers}
                ignore_cc_for_3p_bookers={rule.ignore_cc_for_3p_bookers}
                default_service_charge={venueSettings.default_service_charge}
                default_gratuity={venueSettings.default_gratuity}
                venue_id={window.globalInit.venueId}
                venue_settings={venue_settings}
              />
            )
          },
        },
        {
          content: function () {
            function publicLongDescriptionMarkup() {
              return { __html: rule.public_long_form_description }
            }

            return (
              <div className="section" style={{ paddingTop: 30 }}>
                <modules.slideout.viewModeHeader label="Public description" />
                {venueSettings.is_sizzle_enabled && selected_experience && (
                  <modules.slideout.viewModeElement identifier="Experience/Special Offer" value={selected_experience} />
                )}
                <modules.slideout.viewModeElement identifier="Public time slot description" value={rule.public_time_slot_description} />
                <modules.slideout.viewModeElement identifier="Public description title" value={rule.public_description_title} />
                <modules.slideout.viewModeElement identifier="Public photo" />
                <div style={{ height: 200 }}>
                  <img style={{ height: 200, float: 'right' }} src={rule.public_photo} />
                </div>
                <modules.slideout.viewModeElement identifier="Public long description" />
                <div style={{ padding: 10 }}>
                  <div dangerouslySetInnerHTML={publicLongDescriptionMarkup()} />
                </div>
                <modules.slideout.viewModeElement identifier="Public time slot description" value={rule.public_time_slot_description} />

                {rule.ignore_descriptions_for_3p_bookers && (
                  <modules.slideout.viewModeElement
                    identifier="Allow this rule to be booked on channels that do not support any public description fields"
                    value="Yes"
                  />
                )}

                <modules.slideout.viewModeElement
                  identifier="Automatically Include Upgrades in Reservation?"
                  value={hasAutomaticUpsells ? 'Yes' : 'No'}
                />
                {hasAutomaticUpsells && <modules.slideout.viewModeElement identifier="Included Upgrades" value={includedUpsellDisplay} />}
              </div>
            )
          },
        },
        {
          content() {
            const UpsellsAndReservationTagsSection = (upsellsBody, reservationTagsBody) => (
              <div className="section" style={{ paddingTop: 30 }}>
                <modules.slideout.viewModeHeader label="Upgrades & Reservation Tags" />
                {upsellsBody}
                {reservationTagsBody}
              </div>
            )
            const reservationTagDisplayList = []
            const tagGroupById = {}
            const tagDisplayNameMap = {}
            for (const tagGroup of tag_groups) {
              tagDisplayNameMap[tagGroup.id] = {}
              for (const tagName of tagGroup.tags) {
                tagDisplayNameMap[tagGroup.id][tagName] = tagGroup.tag_name_displays[tagName] || tagName
              }
            }
            for (const tagGroup of tag_groups) {
              tagGroupById[tagGroup.id] = tagGroup
            }
            for (const tagHash of rule.reservation_tags) {
              const tagParts = tagHash.split('##')
              const tagGroupId = tagParts[1]
              const tagName = tagParts[3]
              if (!tagGroupId || !tagName) {
                continue
              }
              const tagGroup = tagGroupById[tagGroupId]

              if (tagGroup) {
                const tagNameDisplay = tagDisplayNameMap[tagGroup.id][tagName]
                if (tagNameDisplay) {
                  reservationTagDisplayList.push(tagNameDisplay)
                }
              }
            }
            const reservationTagNames = (
              <modules.slideout.viewModeElement identifier="Reservation Tags" value={reservationTagDisplayList.join(', ')} />
            )
            if (rule.is_using_shift_upsells) {
              return UpsellsAndReservationTagsSection(
                <modules.slideout.viewModeElement identifier="Upgrade Category" value={'Is using upsells of the shift'} />,
                reservationTagDisplayList.length > 0 ? (
                  reservationTagNames
                ) : (
                  <modules.slideout.viewModeElement identifier="Reservation Tags" value={'No reservation tags selected'} />
                )
              )
            }
            const upsellNames = _.reduce(
              rule.upsell_categories,
              (result, upsell_category_id) => {
                const upsellCategory = upsell_categories[upsell_category_id]
                if (upsellCategory) {
                  if (upsellCategory.label) {
                    return result.concat(<modules.slideout.viewModeElement identifier="Upgrade Category" value={upsellCategory.label} />)
                  }
                }
                return result
              },
              []
            )
            return UpsellsAndReservationTagsSection(
              upsellNames.length > 0 ? (
                upsellNames
              ) : (
                <modules.slideout.viewModeElement identifier="Upgrade Category" value={'No upgrades selected'} />
              ),
              reservationTagDisplayList.length > 0 ? (
                reservationTagNames
              ) : (
                <modules.slideout.viewModeElement identifier="Reservation Tags" value={'No reservation tags selected'} />
              )
            )
          },
        },
      ],
      rule.name,
      { bottom },
      id,
      media_url,
      _.partial(helpers.onMountCallBack, startDay),
      _.partial(helpers.onMountCallBack, startDay),
      onCloseClickHandler,
      1
    )
  }

  var formatDate = function (date) {
    return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
  }

  var addSlide = function (
    id,
    audience_hierarchy,
    venue_name,
    media_url,
    seatingareadata,
    name,
    shift_categories,
    selectedDay,
    startTime,
    endTime,
    addOverlayHandler,
    onCloseClickHandler,
    default_policy,
    default_cancellation_policy,
    payment_setup,
    tax_rate,
    tax_groups,
    can_save_card,
    currency_symbol,
    venue_policy,
    venue_cancellation_policy,
    upsells,
    upsellsToInventory,
    experiences,
    venueSettings,
    existing_rule,
    tag_groups,
    host_page,
    venue_default_booking_policy_id,
    venue_default_cancellation_policy_id,
    is_thefork_integration_enabled,
    is_google_booking_enabled
  ) {
    var use_clone_props = !_.isEqual(existing_rule, {})
    var dateFmt = Pmp.Utils.dateFormat(Pmp.Manager.Global._locale)
    var upsell_categories = upsells.categories

    helpers.mode = 'add'
    var calendar_image = media_url + 'images/icons/calendar-negative.png'
    var ruleObj, startDay, endDay
    if (use_clone_props) {
      var start_date = existing_rule.is_override ? existing_rule.date : existing_rule.start_date
      var end_date = existing_rule.is_override ? existing_rule.date : existing_rule.end_date
      startDay = helpers.convertToJSDate(start_date)
      endDay = end_date ? helpers.convertToJSDate(end_date) : undefined
      ruleObj = _.extend(
        {},
        {
          selectedDays: modules.weekViewManager.getSelectedDays(existing_rule.day_of_week),
          startDay: startDay,
          endDay: endDay,
        },
        existing_rule
      )
      ruleObj.name = name
      ruleObj.shift_categories = existing_rule.shift_categories
    } else {
      var selectedDate = new Date(selectedDay)
      var selectedDays = helpers.weekDaysOptions[selectedDate.toLocaleString('default', { weekday: 'long' })]
      startDay = $.datepicker.formatDate(dateFmt, selectedDate)
      endDay = $.datepicker.formatDate(dateFmt, new Date(selectedDay).addDays(7))
      ruleObj = {
        name: name,
        startDate: selectedDate,
        startDay: startDay,
        endDay: endDay,
        is_indefinite: true,
        start_time_display: startTime,
        end_time_display: endTime,
        selectedDays: [selectedDays],
        seating_area_ids: [],
        audience_tiers: [],
        specific_times_display: [],
        shift_categories: shift_categories,
        table_ids: [],
        access_time_type: 'ALL',
        inventory_type: 'UNLIMITED',
      }
    }
    var use_shift_payment_and_policy = use_clone_props ? existing_rule.use_shift_payment_and_policy : true
    var cc_party_size_min = use_clone_props ? existing_rule.cc_party_size_min : null
    var cc_cost = use_clone_props ? existing_rule.cc_cost : null
    var cc_charge_type = use_clone_props ? existing_rule.cc_charge_type : null
    var cc_apply_tax_rate = use_clone_props ? existing_rule.cc_apply_tax_rate : null
    var cc_gratuity = use_clone_props ? existing_rule.cc_gratuity : null
    var apply_service_charge = use_clone_props ? existing_rule.apply_service_charge : null
    var service_charge_type = use_clone_props ? existing_rule.service_charge_type : 'DEFAULT_SERVICE_CHARGE'
    var service_charge = use_clone_props ? existing_rule.service_charge : null
    var apply_gratuity_charge = use_clone_props ? existing_rule.apply_gratuity_charge : null
    var gratuity_type = use_clone_props ? existing_rule.gratuity_type : 'DEFAULT_GRATUITY'
    var require_gratuity_charge = use_clone_props ? existing_rule.require_gratuity_charge : null
    var tax_group_id = null
    var auto_charge_type = use_clone_props ? existing_rule.auto_charge_type : null
    var auto_charge_amount = use_clone_props ? existing_rule.auto_charge_amount : null
    var auto_charge_amount_in_cents = use_clone_props ? existing_rule.auto_charge_amount_in_cents : null
    var auto_charge_amount_type = use_clone_props ? existing_rule.auto_charge_amount_type : null
    var auto_cutoff_num = use_clone_props ? existing_rule.auto_cutoff_num : null
    var auto_cutoff_type = use_clone_props ? existing_rule.auto_cutoff_type : null
    var auto_cutoff_hour = use_clone_props ? existing_rule.auto_cutoff_hour : null
    var auto_cutoff_hour_display = use_clone_props ? existing_rule.auto_cutoff_hour_display : null
    var cancel_cutoff_num = use_clone_props ? existing_rule.cancel_cutoff_num : null
    var cancel_cutoff_type = use_clone_props ? existing_rule.cancel_cutoff_type : null
    var cancel_cutoff_hour = use_clone_props ? existing_rule.cancel_cutoff_hour : null
    var cancel_cutoff_hour_display = use_clone_props ? existing_rule.cancel_cutoff_hour_display : null
    var require_credit_card = use_clone_props ? existing_rule.require_credit_card : false
    var payment_rule = use_clone_props ? existing_rule.cc_payment_rule : null
    var policy = use_clone_props ? existing_rule.policy : null
    var default_policy_type = venue_default_booking_policy_id || 'default'
    var existing_policy_type = existing_rule.booking_policy_id || existing_rule.policy_type
    var default_cancellation_policy_type = venue_default_cancellation_policy_id || 'default'
    var existing_cancellation_policy_type = existing_rule.payment_policy_id || existing_rule.cancellation_policy_type
    var _policy_type = use_clone_props ? existing_policy_type : default_policy_type
    var policy_type =
      (venue_default_booking_policy_id && _policy_type === 'default') || !_policy_type ? venue_default_booking_policy_id : _policy_type
    var cancellation_policy = use_clone_props ? existing_rule.cancellation_policy : null
    var _cancellation_policy_type = use_clone_props ? existing_cancellation_policy_type : default_cancellation_policy_type
    var cancellation_policy_type =
      (venue_default_cancellation_policy_id && _cancellation_policy_type === 'default') || !_cancellation_policy_type
        ? venue_default_cancellation_policy_id
        : _cancellation_policy_type

    var public_time_slot_description = use_clone_props ? existing_rule.public_time_slot_description : ''
    var public_description_title = use_clone_props ? existing_rule.public_description_title : ''
    var public_photo = use_clone_props ? existing_rule.public_photo : ''
    var public_long_form_description = use_clone_props ? existing_rule.public_long_form_description : ''
    var selectedUpsells = use_clone_props ? existing_rule.upsell_categories : []
    var isUsingShiftUpsells = use_clone_props ? existing_rule.is_using_shift_upsells : false
    var is_recurring = use_clone_props ? existing_rule.is_recurring : false
    var ignore_descriptions_for_3p_bookers = use_clone_props ? existing_rule.ignore_descriptions_for_3p_bookers : false
    var selected_tags = use_clone_props ? existing_rule.reservation_tags : []
    var selected_automatic_upsells = use_clone_props ? existing_rule.selected_automatic_upsells : []
    var ignore_cc_for_3p_bookers = use_clone_props ? existing_rule.ignore_cc_for_3p_bookers : false
    var selected_experience_key = use_clone_props && existing_rule.experience_id

    if (checkUseNewArSlideout()) {
      const onRuleUpdate = () => {
        if (host_page === 'DAYVIEW') {
          modules.accessrulesDayViewManager.refresh(selectedDay)
        } else {
          modules.accessrulesWeekViewManager.refresh(selectedDay)
        }
      }
      window.SvrManager.MgrAccessRulesSlideout.render(
        id,
        {
          startDate: ruleObj.startDate,
          startTimeDisplay: ruleObj.start_time_display,
          endTimeDisplay: ruleObj.end_time_display,
          mode: 'new-item',
          shiftCategories: shift_categories,
          seatingAreaData: seatingareadata,
          audienceHierarchy: audience_hierarchy,
          currencySymbol: currency_symbol,
          canSaveCard: can_save_card,
          venueSettings,
          experiences,
          upsells,
          tagGroups: tag_groups,
          venueName: venue_name,
          selectedDay: selectedDay,
          defaultPolicies: {
            bookingPolicy: venue_policy,
            cancelPolicy: venue_cancellation_policy,
            bookingPolicyId: venue_default_booking_policy_id,
            cancelPolicyId: venue_default_cancellation_policy_id,
          },
          onRuleSave: onRuleUpdate,
          onRuleDelete: onRuleUpdate,
          onClose: onCloseClickHandler,
        },
        switchSlideoutCallback
      )
      return
    }

    const shiftCategoryDisplay = helpers.shiftCategoryOptions[shift_categories[0]]
    const slideoutTitle = shiftCategoryDisplay ? `Add Rule - ${shiftCategoryDisplay}` : 'Add Rule'
    modules.slideout.render(
      'edit',
      [
        {
          name: 'switch-slideout',
          noExpand: true,
          content: () => switchSlideoutComponent,
        },
        {
          label: '1. Inventory',
          content: _.partial(
            helpers.inventorySection,
            ruleObj,
            calendar_image,
            media_url,
            startTime,
            endTime,
            ruleObj.access_time_type,
            ruleObj.specific_times_display,
            seatingareadata,
            addOverlayHandler,
            startDay,
            is_recurring,
            'FOLLOWING',
            host_page,
            is_thefork_integration_enabled,
            is_google_booking_enabled
          ),
        },
        {
          label: '2. Audience',
          content: _.partial(helpers.audienceSection, media_url, ruleObj, audience_hierarchy),
        },
        {
          label: '3. Payment & Policy',
          content: function () {
            return (
              <helpers.paymentAndPolicyTab
                media_url={media_url}
                payment_setup={payment_setup}
                default_policy={default_policy}
                default_cancellation_policy={default_cancellation_policy}
                tax_rate={tax_rate}
                currency_symbol={currency_symbol}
                initial_use_shift_payment_and_policy={use_shift_payment_and_policy}
                initial_require_credit_card={require_credit_card}
                initial_payment_rule={payment_rule}
                initial_policy_type={policy_type}
                initial_policy={policy}
                initial_cancellation_policy_type={cancellation_policy_type}
                initial_cancellation_policy={cancellation_policy}
                can_save_card={can_save_card}
                initial_party_size_min={cc_party_size_min}
                initial_cost={cc_cost}
                initial_charge_type={cc_charge_type}
                initial_apply_tax_rate={cc_apply_tax_rate}
                initial_gratuity={cc_gratuity}
                apply_service_charge={apply_service_charge}
                service_charge_type={service_charge_type}
                service_charge={service_charge}
                apply_gratuity_charge={apply_gratuity_charge}
                gratuity_type={gratuity_type}
                require_gratuity_charge={require_gratuity_charge}
                tax_groups={tax_groups}
                tax_group_id={tax_group_id}
                auto_charge_type={auto_charge_type}
                auto_charge_amount={auto_charge_amount}
                auto_charge_amount_in_cents={auto_charge_amount_in_cents}
                auto_charge_amount_type={auto_charge_amount_type}
                auto_cutoff_num={auto_cutoff_num}
                auto_cutoff_type={auto_cutoff_type}
                auto_cutoff_hour={auto_cutoff_hour}
                auto_cutoff_hour_display={auto_cutoff_hour_display}
                cancel_cutoff_num={cancel_cutoff_num}
                cancel_cutoff_type={cancel_cutoff_type}
                cancel_cutoff_hour={cancel_cutoff_hour}
                cancel_cutoff_hour_display={cancel_cutoff_hour_display}
                ignore_cc_for_3p_bookers={ignore_cc_for_3p_bookers}
                default_service_charge={venueSettings.default_service_charge}
                default_gratuity={venueSettings.default_gratuity}
                venue_settings={venueSettings}
                venue_id={window.globalInit.venueId}
              />
            )
          },
        },
        {
          label: '4. Public Description (Optional)',
          content: function () {
            return (
              <helpers.publicDescriptionTab
                media_url={media_url}
                public_time_slot_description={public_time_slot_description}
                public_description_title={public_description_title}
                public_photo={public_photo}
                public_long_form_description={public_long_form_description}
                ignore_descriptions_for_3p_bookers={ignore_descriptions_for_3p_bookers}
                selectedAutomaticUpsells={selected_automatic_upsells}
                upsellsToInventory={upsellsToInventory}
                upsellCategories={upsell_categories}
                experiences={experiences}
                selected_experience_key={selected_experience_key}
                venueSettings={venueSettings}
              />
            )
          },
        },
        {
          label: '5. Upgrades & Reservation Tags',
          content: function () {
            return (
              <PerksAndUpsells
                selectedUpsells={selectedUpsells}
                upsellCategories={upsell_categories}
                section="ACCESS"
                isUsingShiftUpsells={isUsingShiftUpsells}
                tagGroups={tag_groups}
                selectedTags={selected_tags}
              />
            )
          },
        },
      ],
      slideoutTitle,
      <div
        className="button bottom"
        style={{
          color: '#888',
          padding: '1vh',
          borderTop: '1px solid #CACACA',
        }}
      >
        <a
          style={{ maxHeight: '38px' }}
          onClick={_.partial(
            addRule,
            id,
            venue_name,
            selectedDay,
            tag_groups,
            _.partial(
              viewSlide,
              id,
              audience_hierarchy,
              venue_name,
              media_url,
              shift_categories,
              seatingareadata,
              selectedDay,
              addOverlayHandler,
              onCloseClickHandler,
              payment_setup,
              tax_rate,
              tax_groups,
              can_save_card,
              currency_symbol,
              venue_policy,
              venue_cancellation_policy,
              upsells,
              upsellsToInventory,
              experiences,
              venueSettings
            ),
            host_page,
            audience_hierarchy,
            event,
            venue_default_booking_policy_id,
            venue_default_cancellation_policy_id
          )}
        >
          <span data-test="create-rule">Create Rule</span>
        </a>
      </div>,
      id,
      media_url,
      _.partial(helpers.onMountCallBack, startDay),
      _.partial(helpers.onMountCallBack, startDay),
      onCloseClickHandler,
      1
    )
  }

  return {
    view: viewSlide,
    add: addSlide,
    getAvailableTimes: helpers.getAvailableTimes,
    weekDaysOptions: helpers.weekDaysOptions,
    shiftCategoryOptions: helpers.shiftCategoryOptions,
    convertToJSDate: helpers.convertToJSDate,
  }
})()
